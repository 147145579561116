import './TileShell.scss';

import classNames from 'classnames';
import { memo } from 'react';

const TileShell = ({ children: content, className }) => {
    return (
        <div className={classNames('scct--tile-shell', className)}>
            {content}
        </div>
    );
};

export default memo(TileShell);
