import './ElectricVehicleTypeModal.scss';

import { memo } from 'react';

import BaseModal from '../../../BaseModal/BaseModal';

const fuelTypeLink = `/research/powertrains-and-fuel-types`;

const ElectricVehicleTypeModal = (props) => (
    <BaseModal
        className="electric-vehicle-type-modal"
        ariaLabel="Electric Vehicle Types"
        ariaDescribedby="electric-vehicle-type-modal-copy"
        title="Electric Vehicle Types"
        {...props}
    >
        <div id="electric-vehicle-type-modal-copy">
            <h2>
                <scct-hzn-icon name="lightning-bolt" variant="filled" />{' '}
                Electric
            </h2>
            <p>
                A battery and electric motor are used for propulsion instead of
                a conventional internal combustion engine (ICE). &nbsp;
            </p>

            <h2>
                <scct-hzn-icon name="leaf" variant="filled" /> Hybrid
            </h2>
            <p>
                A conventional internal combustion engine (ICE) powered by
                gasoline combined with a supplemental battery that doesn&apos;t
                need to be plugged in. &nbsp;
            </p>

            <h2>
                <scct-hzn-icon name="plug" variant="filled" /> Plug-in hybrid
            </h2>
            <p>
                A conventional internal combustion engine (ICE) powered by
                gasoline combined with a supplemental battery that can be
                plugged in to recharge. &nbsp;
            </p>
        </div>
        <div className="explore">
            <scct-hzn-button href={fuelTypeLink} variant="cta">
                EXPLORE FUEL TYPES
            </scct-hzn-button>
        </div>
    </BaseModal>
);

export default memo(ElectricVehicleTypeModal);
