import { formatCurrency } from '@/utilities';

import FbsUnavailableContent from './FbsUnavailableContent';
import { AdditionalDownIcon, AlertIcon, ApprovedIcon } from './modalImages';

export default {
    approved: ({ fbsDecision, downPayment }) => {
        const carMonthlyPayment =
            fbsDecision?.terms[0]?.maxTermRate?.monthlyPayment || 0;
        return carMonthlyPayment === 0
            ? {
                  text: 'Purchase with your down payment',
                  type: 'notneeded',
                  hasTerms: false,
                  icon: <AlertIcon />,
                  content: (
                      <p className="scct--fbs-terms--modal-copy">
                          Your down payment exceeds our no-haggle price.
                      </p>
                  ),
                  disclaimerText:
                      'The CarMax no-haggle price does not include taxes, title, fees or any optional items like MaxCare and Accessories.',
              }
            : {
                  text: (
                      <>
                          Pre-qualified with{' '}
                          {formatCurrency(
                              fbsDecision.conditionAmount + downPayment,
                          )}{' '}
                          down
                          <sup>‡</sup>
                      </>
                  ),
                  type: 'preapproved',
                  hasTerms: true,
                  icon: <ApprovedIcon />,
                  disclaimerText: (
                      <>
                          <sup>‡</sup>
                          Estimated pre-qualification terms do not constitute an
                          advertisement or offer of specific credit terms.
                          Trade-in equity, if applicable, is based on the
                          estimated balance on your vehicle loan and your CarMax
                          appraisal offer, which is subject to CarMax’s terms
                          and conditions. If you refresh your appraisal offer
                          and it changes, it can impact your estimated
                          pre-qualification terms. To determine credit
                          availability and specific offer terms, you must submit
                          a credit application which will result in inquiries
                          that appear on your credit report and may impact your
                          credit score and rating.
                      </>
                  ),
              };
    },

    countered: ({ fbsDecision }) => {
        // TODO: This is a temporary way of determining vehicles where pre-approval is unavailable.  FBS will be making a change to determine it on their side and pass a different status instead.
        if (
            fbsDecision &&
            fbsDecision.lenderName === 'Cash' &&
            fbsDecision.totalFinanceAmount === 0
        ) {
            return {
                text: 'Pre-qualification unavailable',
                type: 'declined',
                hasTerms: false,
                icon: <AlertIcon />,
                content: <FbsUnavailableContent />,
            };
        }
        return {
            text: (
                <>
                    Pre-qualified with an additional{' '}
                    {formatCurrency(fbsDecision?.conditionAmount)} down
                    <sup>‡</sup>
                </>
            ),
            type: 'additionaldown',
            hasTerms: true,
            icon: <AdditionalDownIcon />,

            disclaimerText: (
                <>
                    <sup>‡</sup>
                    Estimated pre-qualification terms do not constitute an
                    advertisement or offer of specific credit terms. Trade-in
                    equity, if applicable, is based on the estimated balance on
                    your vehicle loan and your CarMax appraisal offer, which is
                    subject to CarMax’s terms and conditions. If you refresh
                    your appraisal offer and it changes, it can impact your
                    estimated pre-qualification terms. To determine credit
                    availability and specific offer terms, you must submit a
                    credit application which will result in inquiries that
                    appear on your credit report and may impact your credit
                    score and rating.
                </>
            ),
        };
    },
    declined: ({ vehicleData, downPayment }) => {
        // TODO: Temporary until FBS fixes Exeter handling of downpayments that exceed the base price.
        if (downPayment >= vehicleData.basePrice) {
            return {
                text: 'Purchase with your down payment',
                type: 'notneeded',
                hasTerms: false,
                icon: <AlertIcon />,
                content: (
                    <p className="scct--fbs-terms--modal-copy">
                        Your down payment exceeds our no-haggle price.
                    </p>
                ),
                disclaimerText:
                    'The CarMax no-haggle price does not include taxes, title, fees or any optional items like MaxCare and Accessories.',
            };
        }
        return {
            text: 'Pre-qualification unavailable',
            type: 'declined',
            hasTerms: false,
            icon: <AlertIcon />,
            content: <FbsUnavailableContent />,
        };
    },
    default: () => ({
        text: 'Information unavailable',
        type: 'noinfo',
        hasTerms: false,
        icon: <AlertIcon />,
        content: (
            <p className="scct--fbs-terms--modal-copy">
                A technical error occurred on our end.
                <br />
                Check back later.
            </p>
        ),
    }),
};
