import './FbsTermsModal.scss';

import { memo, useEffect, useMemo } from 'react';

import { getTimestamp } from '@/utilities';

import BaseModal from '../BaseModal/BaseModal';
import FbsPriceTable from '../FbsPriceTable/FbsPriceTable';
import FbsAvailableTerms from '../FbsTerms/FbsAvailableTerms';
import modalStatusConfigFunctions from './modalStatusConfigFunctions';

const FbsTermsModal = ({
    fbsApplication,
    fbsDecision,
    vehicleData,
    downPayment,
    onModalShown,
    ...props
}) => {
    useEffect(() => onModalShown?.(), []);

    const modalContent = useMemo(() => {
        if (vehicleData.isDisabled) {
            const configFn = modalStatusConfigFunctions.default;
            return configFn({ fbsDecision });
        } else {
            const configFn =
                modalStatusConfigFunctions[
                    fbsDecision?.decision?.toLowerCase()
                ] || modalStatusConfigFunctions.default;
            return configFn({
                fbsApplication,
                fbsDecision,
                vehicleData,
                downPayment,
            });
        }
    }, [fbsApplication, fbsDecision, downPayment, vehicleData]);
    const carDetailPageLink = `/car/${vehicleData.stockNumber}`;

    const clickHandler = () => {
        const pageID = window?.digitalData?.page?.pageInfo?.pageID;
        if (pageID) {
            const payload = {
                props: {
                    3: pageID,
                    4: 'View All Cars Detail',
                    5: 'Swyft Modal:Preapproval details',
                    6: `${pageID}|View All Cars Detail|Swyft Modal:Preapproval details`,
                    65: getTimestamp(),
                },
            };
            if (typeof _satellite !== 'undefined') {
                // eslint-disable-next-line no-undef
                _satellite.track('searchsite-analytics', payload);
            }
        }
    };

    return (
        <>
            {modalContent ? (
                <BaseModal
                    className="scct--fbs-terms--modal"
                    title={modalContent.text}
                    icon={modalContent.icon}
                    {...props}
                >
                    {modalContent.hasTerms ? (
                        <>
                            <FbsAvailableTerms fbsDecision={fbsDecision} />
                            <FbsPriceTable
                                fbsApplication={fbsApplication}
                                fbsDecision={fbsDecision}
                                vehicleData={vehicleData}
                                downPayment={downPayment}
                            />
                            <scct-hzn-button
                                id="scct--viewButton"
                                variant="cta"
                                href={carDetailPageLink}
                                className="scct--fbs-terms--modal-cta"
                                data-ignore-anchor-tag-rule
                                onClick={clickHandler}
                            >
                                View All Car Details
                            </scct-hzn-button>
                        </>
                    ) : (
                        <>{modalContent.content}</>
                    )}
                    {modalContent.disclaimerText ? (
                        <div
                            className="scct--fbs-terms--modal-disclaimer"
                            id="vehicle-price-disclaimer"
                        >
                            {modalContent.disclaimerText}
                        </div>
                    ) : (
                        <></>
                    )}
                </BaseModal>
            ) : null}
        </>
    );
};

export default memo(FbsTermsModal);
