import { CalculatorStorage, FeeSchedule } from './types/calculator';
import { LocalStorageConstants } from './constants';

function cachedDataNotExpired(cacheDate: Date, maxAgeInDays: number): boolean {
  const expirationDate = new Date(cacheDate);
  const cacheDateDay = cacheDate.getDate();

  expirationDate.setDate(cacheDateDay + maxAgeInDays);
  const today = new Date();

  return today.getTime() < expirationDate.getTime();
}

export const getFinanceInfoFromStore = (): CalculatorStorage | null => {
  let calculatorLocalStorage: CalculatorStorage | null = null;
  const localStorageValue = localStorage.getItem(LocalStorageConstants.key);

  if (localStorageValue) {
    calculatorLocalStorage = JSON.parse(localStorageValue);
  }

  if (
    calculatorLocalStorage &&
    calculatorLocalStorage.updatedDateTime &&
    cachedDataNotExpired(new Date(calculatorLocalStorage.updatedDateTime), LocalStorageConstants.cacheTimeInDays)
  ) {
    return calculatorLocalStorage;
  }

  return null;
};

export const setFinanceInfoObjectToStore = (localStorageValue: CalculatorStorage): void => {
  localStorage.setItem(LocalStorageConstants.key, JSON.stringify(localStorageValue));
};

export const setFinanceInfoToStore = (
  apr: '0' | '1' | '2' | '3' | '4',
  term: string,
  downPayment: number,
  feeState: string,
  feesList: FeeSchedule[],
  monthlyPayment: number | null,
  hasInteraction = false
): void => {
  const localStorageValue: CalculatorStorage = {
    apr,
    term,
    downPayment,
    feeState,
    feesList,
    monthlyPayment,
    hasInteraction,
    updatedDateTime: new Date(),
  };

  setFinanceInfoObjectToStore(localStorageValue);
};
