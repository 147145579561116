import './CloseButton.scss';

import classNames from 'classnames';
import { memo } from 'react';

import CloseIcon from './CloseIcon';

const CloseButton = ({ className, onClick }) => {
    return (
        <button
            className={classNames('scct--close-accessible-button', className)}
            aria-hidden="false"
            aria-label="close"
            tabIndex="0"
            onClick={onClick}
        >
            <CloseIcon />
        </button>
    );
};
export default memo(CloseButton);
