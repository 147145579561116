import './InfoButton.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import InfoIcon from './InfoIcon';

const InfoButton = ({
    disableFocus,
    ariaLabel = 'More info',
    className,
    onClick,
    ...rest
}) => {
    return (
        <button
            type="button"
            className={classNames('scct--info-button', className)}
            onClick={onClick}
            aria-label={ariaLabel}
            tabIndex={disableFocus ? '-1' : '0'}
            {...rest}
        >
            <InfoIcon />
        </button>
    );
};

export default memo(InfoButton);

InfoButton.propTypes = {
    /** Disables focus by setting tabIndex attribute to -1, otherwise tabIndex attribute is 0 */
    disableFocus: PropTypes.bool,
    /** Aria-label attribute for <button> tag */
    ariaLabel: PropTypes.string,
    /** Class name to be added to <button> tag */
    className: PropTypes.string,
    /** Handler for <button> tag click behavior */
    onClick: PropTypes.func,
};
