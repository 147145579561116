import { memo } from 'react';

import BaseModal from '../BaseModal/BaseModal';

const PlaceholderModal = (props) => {
    return (
        <BaseModal ariaLabel="Loading" {...props}>
            <scct-hzn-loading size="large" />
        </BaseModal>
    );
};

export default memo(PlaceholderModal);
