import './EVTaxCreditEligibleModal.scss';

import { memo } from 'react';

import { useModals } from '@/hooks';

import BaseModal from '../BaseModal/BaseModal';

const EVTaxCreditEligibleModal = (props) => {
    const { closeModal } = useModals();

    return (
        <BaseModal
            className="scct--ev-tax-credit-eligible--modal"
            title="EV Tax Credit"
            {...props}
        >
            <p className="body-text">
                Certain EVs and PHEVs may be eligible for a federal used EV tax
                credit up to $4,000. Buyer and car must qualify.
            </p>

            <a
                className="body-text ext-link"
                href="/research/electric-vehicle-guide/can-i-get-a-tax-credit-on-a-used-ev#ev-tax-credit"
            >
                See more details
            </a>

            <div style={{ height: '48px' }}>
                <scct-hzn-button
                    variant="primary"
                    tone="interactive"
                    full-width
                    onClick={closeModal}
                >
                    Close
                </scct-hzn-button>
            </div>
        </BaseModal>
    );
};

export default memo(EVTaxCreditEligibleModal);
