const RightArrowIcon = () => (
    <svg
        width="16"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8 4L7.295 4.705L10.085 7.5L4 7.5L4 8.5L10.085 8.5L7.29 11.29L8 12L12 8L8 4Z"
            fill="white"
        />
    </svg>
);

export default RightArrowIcon;
