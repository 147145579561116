import classNames from 'classnames';
import { memo } from 'react';

// Imported and modified from acquisitions-ui to remove nanoid dependency

const Tab = ({
    className,
    active,
    disabled,
    id,
    inputProps,
    name,
    children,
    index,
    numTabs,
    onClick,
    onBlur,
    onFocus,
}) => {
    const buttonId = `button-${id}`;

    return (
        <button
            className={classNames(className, 'scct--tab-container', {
                active: active,
                first: index === 0,
                last: index === numTabs - 1,
            })}
            id={buttonId}
            name={name}
            disabled={disabled}
            onClick={onClick}
            onBlur={onBlur}
            onFocus={onFocus}
            {...inputProps}
        >
            <label aria-labelledby={buttonId}>{children}</label>
        </button>
    );
};

export default memo(Tab);
