import useBreakpoint from 'use-breakpoint';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 960 };

export const useGetBreakPoints = () => {
    const { breakpoint } = useBreakpoint(BREAKPOINTS);
    switch (breakpoint) {
        case 'desktop':
            return { isPhone: false, isTablet: false, isDesktop: true };
        case 'tablet':
            return { isPhone: false, isTablet: true, isDesktop: false };
        case 'mobile':
            return { isPhone: true, isTablet: false, isDesktop: false };
        default:
            if (process.env.NODE_ENV.startsWith('test')) {
                return { isPhone: true, isTablet: false, isDesktop: false };
            } else {
                throw `Unrecognized breakpoint: ${breakpoint}`;
            }
    }
};
