const HeartIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.41 3.18005C15.59 2.83005 13.38 3.45005 12 5.48005C10.63 3.45005 8.41004 2.83005 6.59004 3.18005C4.60004 3.56005 2.29004 5.32005 2.29004 9.00005C2.29004 14.02 6.99004 19.1 11.21 20.51C11.47 20.59 11.73 20.64 12 20.64C12.27 20.64 12.54 20.6 12.79 20.51C17.01 19.1 21.71 14.03 21.71 9.00005C21.71 5.32005 19.39 3.56005 17.41 3.18005Z"
            fill="white"
        />
        <path
            d="M12 20.63C11.73 20.63 11.46 20.59 11.21 20.5C6.99004 19.09 2.29004 14.02 2.29004 8.99004C2.29004 5.31004 4.61004 3.55004 6.59004 3.17004C8.41004 2.82004 10.62 3.44004 12 5.47004C13.37 3.44004 15.59 2.82004 17.41 3.17004C19.4 3.55004 21.71 5.31004 21.71 8.99004C21.71 14.01 17.01 19.09 12.79 20.5C12.53 20.59 12.27 20.63 12 20.63ZM11.66 19.16C11.88 19.23 12.12 19.23 12.34 19.16C16.09 17.91 20.29 13.29 20.29 9.00004C20.29 6.19004 18.66 4.87004 17.14 4.58004C15.57 4.28004 13.57 4.99004 12.66 7.27004C12.44 7.81004 11.56 7.81004 11.34 7.27004C10.43 4.99004 8.43004 4.28004 6.86004 4.58004C5.34004 4.87004 3.71004 6.19004 3.71004 9.00004C3.71004 13.29 7.91004 17.9 11.66 19.16Z"
            fill="white"
        />
        <path
            d="M12 20.63C11.73 20.63 11.46 20.59 11.21 20.5C6.99004 19.09 2.29004 14.02 2.29004 8.99004C2.29004 5.31004 4.61004 3.55004 6.59004 3.17004C8.41004 2.82004 10.62 3.44004 12 5.47004C13.37 3.44004 15.59 2.82004 17.41 3.17004C19.4 3.55004 21.71 5.31004 21.71 8.99004C21.71 14.01 17.01 19.09 12.79 20.5C12.53 20.59 12.27 20.63 12 20.63ZM11.66 19.16C11.88 19.23 12.12 19.23 12.34 19.16C16.09 17.91 20.29 13.29 20.29 9.00004C20.29 6.19004 18.66 4.87004 17.14 4.58004C15.57 4.28004 13.57 4.99004 12.66 7.27004C12.44 7.81004 11.56 7.81004 11.34 7.27004C10.43 4.99004 8.43004 4.28004 6.86004 4.58004C5.34004 4.87004 3.71004 6.19004 3.71004 9.00004C3.71004 13.29 7.91004 17.9 11.66 19.16Z"
            fill="#2A343D"
        />
    </svg>
);

export default HeartIcon;
