/**
 * The locale to use for number formatting.
 */
const locale = 'en-US';

/**
 * Converts the passed value to a number.
 *
 * @param {any} value
 * @returns {number}
 */
const getRawNumber = (value) => {
    if (typeof value === 'number' && Number.isFinite(value)) {
        return value;
    }

    const stringValue = String(value).trim();

    // Remove any characters that aren't a number or period.
    const strippedValue = stringValue.replace(/[^\d.]/g, '');

    // Ensure that we can work with negative numbers.
    const sign = stringValue.startsWith('-') ? '-' : '';

    return Number(`${sign}${strippedValue}`) || 0;
};

/**
 * Format a number as USD currency.
 *
 * @param {number} price
 * @param {boolean} showCents
 * @returns {string}
 */
export const formatCurrency = (amount, showCents = false) => {
    const number = getRawNumber(amount);
    const decimalPlaces = showCents ? 2 : 0;
    const options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    };

    return number.toLocaleString(locale, options);
};
