import {
    cloneElement,
    Suspense,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { lock, unlock } from 'tua-body-scroll-lock';

import { ModalContext } from '@/context/ModalContext';

import PlaceholderModal from '../PlaceholderModal/PlaceholderModal';

const ModalHandler = ({ children }) => {
    const modalContent = useRef(null);
    const [modal, setModal] = useState(null);

    useEffect(() => {
        if (
            globalThis.document &&
            !document.querySelector('#modal-placeholder')
        ) {
            const placeholder = document.createElement('Div');
            placeholder.setAttribute('id', 'modal-placeholder');
            document.body.appendChild(placeholder);
        }
    }, []);

    const register = useCallback((ref) => {
        const scrollHost = ref?.querySelector('.scct--scrollhost');
        if (scrollHost) {
            lock(scrollHost);
        } else {
            unlock(modalContent.current);
        }
        modalContent.current = scrollHost;
    }, []);

    const closeModal = useCallback(() => setModal(null), []);
    const openModal = useCallback(
        (component) =>
            new Promise((resolve) => {
                const onClose = (...arg) => {
                    closeModal();
                    component.props.onClose?.(...arg);
                    resolve([...arg]);
                };
                setModal(
                    cloneElement(component, {
                        open: true,
                        onClose,
                        register,
                        closeModal: onClose,
                    }),
                );
            }),
        [closeModal, register],
    );

    const context = useMemo(
        () => ({ closeModal, openModal }),
        [closeModal, openModal],
    );

    return (
        <ModalContext.Provider value={context}>
            {children}
            {globalThis.window && (
                <Suspense
                    fallback={
                        <PlaceholderModal open={true} register={() => {}} />
                    }
                >
                    {modal}
                </Suspense>
            )}
        </ModalContext.Provider>
    );
};

export default ModalHandler;
