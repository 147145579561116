import './ImageGallery.scss';

import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { SwiperContainer, SwiperSlide } from 'swiper/element/bundle';

import { parseImageTag } from '@/utilities';

import badgeIcons from '../shared/badges';
import ImageGalleryImage from './ImageGalleryImage';
import { getImageUrls, getLegacyImageUrls } from './imageProcessor';
import { LeftArrow } from './LeftArrow';
import { RightArrow } from './RightArrow';

/**
 * Copies the functionality of Swiper's `register` method, but registers a custom element name for the container.
 *
 * When updating Swiper, ensure that the contents of the `register` method are reflected here.
 */
const registerSwiper = () => {
    if (typeof window === 'undefined') {
        return;
    }

    // Adds the sc- prefix to prevent conflicts with external Swiper instances.
    if (!window.customElements.get('sc-swiper-container')) {
        window.customElements.define(
            'sc-swiper-container',
            class extends SwiperContainer {},
        );
    }

    // Need to keep the original element name since Swiper uses it internally.
    if (!window.customElements.get('swiper-slide')) {
        window.customElements.define(
            'swiper-slide',
            class extends SwiperSlide {},
        );
    }
};

registerSwiper();

/**
 * Determine if the current environment supports touch events.
 */
const supportsTouch = () => {
    if (typeof window !== 'undefined' && 'ontouchstart' in window) {
        return true;
    }

    if (
        typeof navigator !== 'undefined' &&
        (navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0)
    ) {
        return true;
    }

    return false;
};

const ImageGallery = ({
    badges,
    getCarImages,
    imageAltText,
    imageHref,
    imageUrl,
    onScrollImages,
    stockNumber,
    viewMoreHref,
    lazyLoad,
    disabled,
}) => {
    const swiper = useRef();
    const prev = useRef(null);
    const next = useRef(null);

    // Putting all local state in a single object so that multiple pieces can be updated at once.
    const [state, setState] = useState({
        images: [],
        imagesLoaded: false,
        loading: false,
    });
    const { images, imagesLoaded, loading } = state;

    /**
     * Get the list of badges to show.
     */
    const badgeList = useMemo(() => {
        return (
            badges?.filter((badge) => badge !== 'warranty')?.slice(0, 3) ?? []
        );
    }, [badges]);

    /**
     * Create the Swiper instance.
     */
    useEffect(() => {
        const swiperEl = swiper.current;

        if (swiperEl?.initialized === true) {
            return () => {};
        }

        Object.assign(swiperEl, {
            followFinger: false,
            navigation: {
                enabled: true,
                nextEl: next.current,
                prevEl: prev.current,
            },
            pagination: {
                dynamicBullets: true,
                enabled: supportsTouch(),
            },
            // Fixes 1px gap on subpixel widths.
            slidesPerView: 'auto',
            swiperElementNodeName: 'SC-SWIPER-CONTAINER',
        });

        const handleFirstSlideChange = () => {
            setState((prevState) => ({ ...prevState, loading: true }));
        };

        swiperEl.addEventListener(
            'swiperbeforeslidechangestart',
            handleFirstSlideChange,
            { once: true },
        );

        swiperEl.initialize();
    }, []);

    /**
     * Handles changes to `disabled` state.
     */
    useEffect(() => {
        const swiperEl = swiper.current;

        if (disabled && swiperEl.swiper.enabled) {
            swiperEl.swiper.disable();
        } else if (!disabled && !swiperEl.swiper.enabled) {
            swiperEl.swiper.enable();
            // Not sure why, but navigation needs to be manually re-enabled.
            swiperEl.swiper.navigation.enable();
        }
    }, [disabled]);

    /**
     * Additional configuration of the Swiper instance.
     */
    useEffect(() => {
        const swiperEl = swiper.current;

        /**
         * When a slide changes, call `onScrollImages`.
         */
        const handleActiveIndexChange = (e) => {
            const [swiperInstance] = e.detail;

            if (onScrollImages) {
                onScrollImages(swiperInstance.previousIndex === 0);
            }
        };

        swiperEl.addEventListener(
            'swiperactiveindexchange',
            handleActiveIndexChange,
        );

        return () => {
            swiperEl?.removeEventListener(
                'swiperactiveindexchange',
                handleActiveIndexChange,
            );
        };
    }, [onScrollImages]);

    /**
     * Load additional car images.
     */
    useEffect(() => {
        if (!loading) {
            return;
        }

        getCarImages(
            stockNumber,
            (res) => {
                let additionalImages = [];

                // Legacy API: All consumers should point to the media delivery service.
                if (res.images) {
                    additionalImages = getLegacyImageUrls(res.images);
                } else {
                    additionalImages = getImageUrls(res.items);
                }

                setState((prevState) => ({
                    ...prevState,
                    images: additionalImages,
                    imagesLoaded: true,
                }));
            },
            () => {
                setState((prevState) => ({ ...prevState, imagesLoaded: true }));
            },
        );
    }, [getCarImages, loading, stockNumber]);

    /**
     * Update the Swiper instance after the images have loaded.
     */
    useEffect(() => {
        if (!imagesLoaded) {
            return;
        }

        const swiperEl = swiper.current;

        // Restore the `followFinger` functionality for better UX.
        swiperEl.setAttribute('follow-finger', 'true');

        // Ensure we are on the second slide.
        swiperEl.swiper.slideTo(1, 0);

        // Update loading state after a brief timeout, to ensure slide transitions have finished.
        setTimeout(() => {
            setState((prevState) => ({ ...prevState, loading: false }));
        }, 500);
    }, [imagesLoaded]);

    return (
        <div
            className="scct--image-gallery"
            data-clickprops={`Element type: Car Tile Image,StockNumber: ${stockNumber}`}
        >
            <button
                ref={prev}
                type="button"
                className="scct--image-gallery__nav scct--image-gallery__nav--prev"
                aria-label="Previous slide"
                data-clickprops={'Element type: Image Carousel Nav'}
                data-direction="left"
                hidden={disabled || undefined}
            >
                <LeftArrow />
            </button>
            <button
                ref={next}
                type="button"
                className="scct--image-gallery__nav scct--image-gallery__nav--next"
                aria-label="Next slide"
                data-clickprops={'Element type: Image Carousel Nav'}
                data-direction="right"
                hidden={disabled || undefined}
            >
                <RightArrow />
            </button>
            <sc-swiper-container ref={swiper} init="false">
                <swiper-slide>
                    <ImageGalleryImage
                        href={imageHref}
                        src={imageUrl}
                        alt={imageAltText}
                        lazyLoad={lazyLoad}
                    />
                </swiper-slide>
                {images.map((image) => (
                    <swiper-slide key={image.src}>
                        <ImageGalleryImage
                            href={imageHref}
                            src={image.src}
                            alt={imageAltText + parseImageTag(image.tag, ' - ')}
                        />
                    </swiper-slide>
                ))}
                {badgeList.length > 0 ? (
                    <swiper-slide>
                        <a
                            href={viewMoreHref}
                            rel="nofollow"
                            tabIndex={-1}
                            className="scct--image-gallery__badges"
                        >
                            {badgeList.map((badge) => (
                                <div
                                    key={badge}
                                    className="scct--image-gallery__badge"
                                >
                                    {badgeIcons[badge]?.icon}
                                    <p>{badgeIcons[badge]?.message}</p>
                                </div>
                            ))}
                        </a>
                    </swiper-slide>
                ) : null}
                <swiper-slide>
                    <a
                        href={viewMoreHref}
                        rel="nofollow"
                        tabIndex={-1}
                        className="scct--image-gallery__view-more"
                    >
                        <span className="scct--image-gallery__view-more-button">
                            View More
                        </span>
                    </a>
                </swiper-slide>
            </sc-swiper-container>
            {loading ? (
                <div className="scct--image-gallery__loading">
                    <scct-hzn-loading size="small" />
                </div>
            ) : null}
        </div>
    );
};

ImageGallery.propTypes = {
    badges: PropTypes.array,
    getCarImages: PropTypes.func,
    imageAltText: PropTypes.string,
    imageHref: PropTypes.string,
    imageUrl: PropTypes.string,
    onScrollImages: PropTypes.func,
    stockNumber: PropTypes.number,
    viewMoreHref: PropTypes.string,
    lazyLoad: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default memo(ImageGallery);
