import './MonthlyPaymentInfo.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

import { formatCurrency } from '@/utilities';

import FbsDecisionType from '../CarTile/fbsDecision.proptypes';
import InfoButton from '../InfoButton/InfoButton';

const format = (value) => {
    if (typeof value === 'number') {
        return formatCurrency(Math.max(0, value));
    }

    return `${value}`;
};

const MonthlyPaymentInfo = ({
    fbsDecision,
    isComingSoon = false,
    monthlyPayment,
    minMonthlyPayment,
    maxMonthlyPayment,
    onClick,
    className,
}) => {
    const formattedMonthlyPayment = useMemo(() => {
        if (minMonthlyPayment && maxMonthlyPayment) {
            return `${format(minMonthlyPayment)} - ${format(maxMonthlyPayment)}`;
        }

        return format(monthlyPayment);
    }, [monthlyPayment, minMonthlyPayment, maxMonthlyPayment]);

    return (
        <p className={classNames('scct--monthly-payment-info', className)}>
            {!fbsDecision ||
            (!isComingSoon &&
                ['approved', 'countered'].includes(fbsDecision.decision)) ? (
                <>
                    {formattedMonthlyPayment}/mo
                    {onClick && (
                        <InfoButton
                            onClick={() => onClick(formattedMonthlyPayment)}
                            ariaLabel="more info about monthly payment"
                        />
                    )}
                </>
            ) : (
                <>&#8288;</>
            )}
        </p>
    );
};

MonthlyPaymentInfo.propTypes = {
    fbsDecision: FbsDecisionType,
    isComingSoon: PropTypes.bool,
    monthlyPayment: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minMonthlyPayment: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    maxMonthlyPayment: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default memo(MonthlyPaymentInfo);
