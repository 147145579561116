export const ApprovedIcon = () => {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M76.6667 39.9667L68.5333 30.6667L69.6667 18.3667L57.6333 15.6333L51.3333 5L40 9.86667L28.6667 5L22.3667 15.6333L10.3333 18.3333L11.4667 30.6667L3.33334 39.9667L11.4667 49.2667L10.3333 61.6L22.3667 64.3333L28.6667 75L40 70.1L51.3333 74.9667L57.6333 64.3333L69.6667 61.6L68.5333 49.3L76.6667 39.9667ZM63.5 44.9L61.6333 47.0667L61.9 49.9L62.5 56.4L56.1667 57.8333L53.3667 58.4667L51.9 60.9333L48.6 66.5333L42.6667 63.9667L40 62.8333L37.3667 63.9667L31.4333 66.5333L28.1333 60.9667L26.6667 58.5L23.8667 57.8667L17.5333 56.4333L18.1333 49.9L18.4 47.0667L16.5333 44.9L12.2333 40L16.5333 35.0667L18.4 32.9L18.1 30.0333L17.5 23.5667L23.8333 22.1333L26.6333 21.5L28.1 19.0333L31.4 13.4333L37.3333 16L40 17.1333L42.6333 16L48.5667 13.4333L51.8667 19.0333L53.3333 21.5L56.1333 22.1333L62.4667 23.5667L61.8667 30.0667L61.6 32.9L63.4667 35.0667L67.7667 39.9667L63.5 44.9Z"
                fill="#005AB8"
            ></path>
            <path
                d="M33 44.6L25.2667 36.8333L20.3333 41.8L33 54.5L57.4667 29.9667L52.5333 25L33 44.6Z"
                fill="#005AB8"
            ></path>
        </svg>
    );
};

export const AdditionalDownIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
        >
            <path
                d="M3.00002 39.9833L11.1606 30.6856L10.0234 18.3779L22.097 15.6355L28.4181 5L39.7893 9.88294L51.1606 5L57.4816 15.6355L69.5552 18.3445L68.4181 30.6522L76.5786 39.9833L68.4181 49.2809L69.5552 61.6221L57.4816 64.3645L51.1606 75L39.7893 70.0836L28.4181 74.9666L22.097 64.3311L10.0234 61.5886L11.1606 49.2809L3.00002 39.9833ZM18.0836 47.0401L17.2141 56.3712L26.3779 58.4448L31.1606 66.505L39.7893 62.7926L48.4181 66.505L53.2007 58.4448L62.3646 56.3712L61.495 47.0067L67.6823 39.9833L61.495 32.893L62.3646 23.5953L53.2007 21.5552L48.4181 13.495L39.7893 17.1739L31.1606 13.4615L26.3779 21.5217L17.2141 23.5953L18.0836 32.9264L11.8963 39.9833L18.0836 47.0401ZM43.1338 50.0167H36.4448V56.7057H43.1338V50.0167ZM43.1338 23.2609H36.4448L36.4448 43.3278H43.1338L43.1338 23.2609Z"
                fill="#005AB8"
            />
        </svg>
    );
};

export const AlertIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="68"
            viewBox="0 0 68 68"
            fill="none"
        >
            <path
                d="M30.6667 17.3333H37.3334V24H30.6667V17.3333ZM30.6667 30.6667H37.3334V50.6667H30.6667V30.6667ZM34.0001 0.666664C15.6001 0.666664 0.666748 15.6 0.666748 34C0.666748 52.4 15.6001 67.3333 34.0001 67.3333C52.4001 67.3333 67.3334 52.4 67.3334 34C67.3334 15.6 52.4001 0.666664 34.0001 0.666664ZM34.0001 60.6667C19.3001 60.6667 7.33342 48.7 7.33342 34C7.33342 19.3 19.3001 7.33333 34.0001 7.33333C48.7001 7.33333 60.6668 19.3 60.6668 34C60.6668 48.7 48.7001 60.6667 34.0001 60.6667Z"
                fill="#0559AD"
            />
        </svg>
    );
};
