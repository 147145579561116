import { memo } from 'react';

const FbsUnavailableContent = () => {
    return (
        <div className="scct--fbs-terms--modal-unavailable">
            <p className="scct--fbs-terms--modal-copy">
                We weren&#700;t able to pre-qualify you for financing at this
                time.
            </p>
            <h2>Let&#700;s review your options.</h2>
            <h3>Bring your own financing</h3>
            <p className="scct--fbs-terms--modal-copy">
                We accept financing from most banks and outside lenders. Let us
                know and we&#700;ll help make the process easy.
            </p>
            <h3>Add a co-applicant</h3>
            <p className="scct--fbs-terms--modal-copy">
                Applying with a co-applicant may help you get the car, or the
                terms you want.
            </p>
            <h3>Re-apply down the road</h3>
            <p className="scct--fbs-terms--modal-copy">
                Should your situation change, feel free to reach out so we can
                further assist.
            </p>
        </div>
    );
};

export default memo(FbsUnavailableContent);
