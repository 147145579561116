const InfoIcon = () => (
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_3730_223)">
            <path
                d="M6 0.5C2.97 0.5 0.5 2.97 0.5 6C0.5 9.03 2.97 11.5 6 11.5C9.03 11.5 11.5 9.03 11.5 6C11.5 2.97 9.03 0.5 6 0.5ZM6 10.5C3.52 10.5 1.5 8.48 1.5 6C1.5 3.52 3.52 1.5 6 1.5C8.48 1.5 10.5 3.52 10.5 6C10.5 8.48 8.48 10.5 6 10.5Z"
                fill="#005AB8"
            />
            <path
                d="M6 4.53003C6.41421 4.53003 6.75 4.19424 6.75 3.78003C6.75 3.36582 6.41421 3.03003 6 3.03003C5.58579 3.03003 5.25 3.36582 5.25 3.78003C5.25 4.19424 5.58579 4.53003 6 4.53003Z"
                fill="#005AB8"
            />
            <path
                d="M7.10989 7.71994H6.49989V5.43994C6.49989 5.15994 6.27989 4.93994 5.99989 4.93994H4.88989C4.60989 4.93994 4.38989 5.15994 4.38989 5.43994C4.38989 5.71994 4.60989 5.93994 4.88989 5.93994H5.49989V7.71994H4.88989C4.60989 7.71994 4.38989 7.93994 4.38989 8.21994C4.38989 8.49994 4.60989 8.71994 4.88989 8.71994H7.10989C7.38989 8.71994 7.60989 8.49994 7.60989 8.21994C7.60989 7.93994 7.38989 7.71994 7.10989 7.71994Z"
                fill="#005AB8"
            />
        </g>
        <defs>
            <clipPath id="clip0_3730_223">
                <rect width="12" height="12" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default InfoIcon;
