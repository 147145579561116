import './CarTile.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useModals } from '@/hooks/useModals';

import ActionMenu from '../CarTileActionMenu/ActionMenu';
import MenuType from '../CarTileActionMenu/menu.proptypes';
import EVTaxCreditEligibleModal from '../EVTaxCreditEligible/EVTaxCreditEligibleModal';
import FbsTerms from '../FbsTerms/FbsTerms';
import ImageGallery from '../ImageGallery/ImageGallery';
import MakeModelInfo from '../MakeModelInfo/MakeModelInfo';
import MonthlyPaymentInfo from '../MonthlyPaymentInfo/MonthlyPaymentInfo';
import PriceMilesInfo from '../PriceMilesInfo/PriceMilesInfo';
import Ribbon from '../Ribbon/Ribbon';
import SaveCarButton from '../SaveCarButton/SaveCarButton';
import StoreTransferInfo from '../StoreTransferInfo/StoreTransferInfo';
import TestConfigHandler from '../TestConfigHandler/TestConfigHandler';
import TileShell from '../TileShell/TileShell';
import ElectricVehicleTypeInfo from './ElectricVehicleTypeInfo/ElectricVehicleTypeInfo';
import FbsApplicationType from './fbsApplication.proptypes';
import FbsDecisionType from './fbsDecision.proptypes';
import RightArrowIcon from './svg/RightArrowIcon';

/**
 * Use this as the default value to prevent unnecessary re-renders due to a new empty object "changing" the context.
 */
const defaultTestConfig = {};

/**
 * Build a list of CSS selectors to determine if an element inside the content area should be clickable without
 * relocating the user to the detail page.
 */
const clickableContentSelectors = ['a', 'button', '[data-interactive="true"]']
    .map((selector) => `${selector}, ${selector} *`)
    .join(', ');

const CarTile = ({
    car,
    position = 0,
    lazyLoad = false,
    showFavorites = false,
    viewed = false,
    isFavorite = false,
    isDisabled = false,
    onUnfavorite,
    onFavorite,
    recommendationType,
    getCarImages,
    disableScrollNav = false,
    menuItems,
    criticalMenuItem,
    fbsDecision,
    feesList,
    ribbonText,
    ribbonStyle,
    onMenuOpen,
    handleToggleCompare,
    imageUrl,
    hasInteraction = false,
    isCompareMode = false,
    isCarSelectedForCompare = false,
    fbsApplication,
    downPayment,
    padTileForPendingDecision = false,
    monthlyPayment,
    minMonthlyPayment,
    maxMonthlyPayment,
    showElectricVehicleInfo = false,
    onPopoverToggled,
    onClickMonthlyPayment,
    onScrollImages,
    onFbsTermsModalShown,
    postCalculatorTileTestEnabled = false,
    tagMonthlyPaymentInfo,
    pageName = '',
    isFluidWidth = false,
    children,
    navigateOnContentClick = false,
    isCompact = false,
    testConfig = defaultTestConfig,
}) => {
    const {
        stockNumber,
        year,
        make,
        model,
        trim,
        basePrice,
        originalPrice,
        mileage,
        storeName,
        stateAbbreviation,
        highlights,
        isComingSoon,
        exteriorColor,
        transferText,
        engineType,
        transferTimesUnavailable,
        minEstimatedTransferDurationInDays,
        maxEstimatedTransferDurationInDays,
        hasPriceDrop,
        isEVTaxCreditEligible,
    } = car;
    const thisTile = useRef();
    const carouselNav = useRef();
    const imagesRef = useRef();
    const menuContainer = useRef(null);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const { openModal } = useModals();
    const [isHovered, setIsHovered] = useState(false);
    const url = `/car/${stockNumber}`;

    const heartPosition = testConfig?.heartPosition
        ? testConfig.heartPosition
        : 'left';
    const badgePosition = heartPosition === 'left' ? 'right' : 'left';

    const hasMakeModelInfo = year && make && model;
    const hasPriceMilesInfo = stockNumber && basePrice && mileage;
    const hasStoreTransferInfo = transferText;

    const getVehicleData = {
        basePrice,
        stockNumber,
        year,
        make,
        model,
        trim,
        stateCode: stateAbbreviation,
        feesList,
        isDisabled,
    };

    const makeModelInfo = `${year} ${make} ${model}` + (trim ? ` ${trim}` : '');
    const imageAltText = exteriorColor
        ? `${exteriorColor} ${makeModelInfo}`
        : makeModelInfo;

    useEffect(() => {
        if (monthlyPayment && hasInteraction) {
            tagMonthlyPaymentInfo?.(postCalculatorTileTestEnabled);
        }
    });

    useEffect(() => {
        const navSelector = '.scct--image-gallery__nav';
        carouselNav.current = thisTile.current.querySelectorAll(navSelector);
    }, []);

    const handleToggleMenu = useCallback(
        (open) => {
            if (open) {
                onMenuOpen?.();
            }

            onPopoverToggled?.(open);
            setIsPopoverOpen(open);
        },
        [onMenuOpen, onPopoverToggled],
    );

    const hoverOn = useCallback(
        ({ pointerType }) => {
            if (!disableScrollNav && pointerType === 'mouse') {
                setIsHovered(true);
            }
        },
        [disableScrollNav],
    );

    const hoverOff = useCallback(
        ({ pointerType }, force = false) => {
            if (!disableScrollNav && (pointerType === 'mouse' || force)) {
                setIsHovered(false);
            }
        },
        [disableScrollNav],
    );

    let prequalAppLink = `/pre-qual/app?stockNumber=${stockNumber}`;
    if (pageName) {
        prequalAppLink = `${prequalAppLink}&op=${pageName}`;
    }

    const ribbonContent = useMemo(() => {
        if (isEVTaxCreditEligible) {
            return (
                <Ribbon
                    content={{ text: 'EV Tax Credit' }}
                    ribbonStyle="ev-tax-credit"
                    infoAction={() => openModal(<EVTaxCreditEligibleModal />)}
                    className={badgePosition}
                />
            );
        }
        if (hasPriceDrop) {
            return (
                <Ribbon
                    content={{ text: 'Price Drop' }}
                    ribbonStyle="price-drop"
                    className={badgePosition}
                />
            );
        }

        if (ribbonText) {
            return (
                <Ribbon
                    content={{ text: ribbonText }}
                    ribbonStyle={ribbonStyle}
                    className={badgePosition}
                />
            );
        }

        return null;
    }, [hasPriceDrop, ribbonText, ribbonStyle, badgePosition]);

    const handleContentAreaClick = (event) => {
        if (event.target?.matches(clickableContentSelectors)) {
            return false;
        }

        window.location.href = url;
    };

    return (
        <TestConfigHandler value={testConfig}>
            <article
                className={classNames('scct--car-tile', 'car-tile', {
                    disabled: isDisabled,
                    fluid: isFluidWidth,
                    'popover-open': isPopoverOpen,
                    'scct--car-tile--hovered': isHovered,
                    'scct--car-tile--compact': isCompact,
                })}
                onPointerEnter={!isDisabled ? hoverOn : undefined}
                onPointerLeave={!isDisabled ? hoverOff : undefined}
                ref={thisTile}
                key={stockNumber}
                tabIndex="-1"
                data-clickprops={`Element type: Car Tile,StockNumber: ${stockNumber},YMM: ${year} ${make} ${model},Price: ${basePrice},Coming Soon: ${isComingSoon},Marked Down: ${!!hasPriceDrop},Position: ${position + 1}`}
                data-position={position}
                data-id={stockNumber}
                data-ignore-anchor-tag-rule
                data-recommendation={recommendationType}
                aria-label={makeModelInfo}
            >
                <div ref={menuContainer}></div>

                {showFavorites && onFavorite && onUnfavorite && (
                    <SaveCarButton
                        makeModelInfo={makeModelInfo}
                        stockNumber={stockNumber}
                        isFavorite={isFavorite}
                        onFavorite={onFavorite}
                        onUnfavorite={onUnfavorite}
                        heartPosition={heartPosition}
                    />
                )}

                <TileShell className={classNames({ 'popover-open': viewed })}>
                    <div className="scct--car-tile--images" ref={imagesRef}>
                        {ribbonContent}
                        <ImageGallery
                            badges={highlights}
                            getCarImages={getCarImages}
                            imageAltText={imageAltText}
                            imageHref={url}
                            imageUrl={imageUrl}
                            onScrollImages={onScrollImages}
                            stockNumber={stockNumber}
                            viewMoreHref={url}
                            lazyLoad={lazyLoad}
                            disabled={isDisabled || disableScrollNav}
                        />
                    </div>
                    <div
                        className="scct--car-tile--content"
                        data-navigate-on-click={
                            navigateOnContentClick || undefined
                        }
                        onClick={
                            navigateOnContentClick
                                ? handleContentAreaClick
                                : undefined
                        }
                    >
                        {menuItems ? (
                            <ActionMenu
                                menuItems={menuItems}
                                isCompareMode={isCompareMode}
                                isCarSelectedForCompare={
                                    isCarSelectedForCompare
                                }
                                handleToggleCompare={handleToggleCompare}
                                criticalMenuItem={criticalMenuItem}
                                handleToggleMenu={handleToggleMenu}
                                menuContainerRef={menuContainer}
                            />
                        ) : null}

                        {hasMakeModelInfo && (
                            <MakeModelInfo
                                href={url}
                                year={year}
                                make={make}
                                model={model}
                                trim={trim}
                            />
                        )}
                        {hasPriceMilesInfo ? (
                            <PriceMilesInfo
                                stockNumber={stockNumber}
                                basePrice={basePrice}
                                originalPrice={originalPrice}
                                mileage={mileage}
                                hasPriceDrop={hasPriceDrop}
                                isCompact={isCompact}
                            >
                                {showElectricVehicleInfo && !isCompact ? (
                                    <ElectricVehicleTypeInfo
                                        engineTypeIcon={engineType}
                                        isCompact={isCompact}
                                    />
                                ) : null}
                            </PriceMilesInfo>
                        ) : null}

                        {monthlyPayment &&
                            hasInteraction &&
                            !postCalculatorTileTestEnabled && (
                                <MonthlyPaymentInfo
                                    fbsDecision={fbsDecision}
                                    isComingSoon={isComingSoon}
                                    monthlyPayment={monthlyPayment}
                                    onClick={onClickMonthlyPayment}
                                />
                            )}

                        {hasStoreTransferInfo && (
                            <>
                                {!fbsDecision &&
                                    !padTileForPendingDecision &&
                                    (!hasInteraction ||
                                        !postCalculatorTileTestEnabled) && (
                                        <hr />
                                    )}
                                <StoreTransferInfo
                                    storeName={storeName}
                                    storeState={stateAbbreviation}
                                    transferText={transferText}
                                    transferTimeMin={
                                        minEstimatedTransferDurationInDays
                                    }
                                    transferTimeMax={
                                        maxEstimatedTransferDurationInDays
                                    }
                                    transferTimesUnavailable={
                                        transferTimesUnavailable
                                    }
                                >
                                    {showElectricVehicleInfo && isCompact ? (
                                        <ElectricVehicleTypeInfo
                                            engineTypeIcon={engineType}
                                            isCompact={isCompact}
                                        />
                                    ) : null}
                                </StoreTransferInfo>
                            </>
                        )}

                        {hasInteraction &&
                            postCalculatorTileTestEnabled &&
                            !fbsApplication && (
                                <div
                                    className={classNames(
                                        'post-calculator-terms',
                                        {
                                            padded: !minMonthlyPayment,
                                        },
                                    )}
                                >
                                    <hr />
                                    <MonthlyPaymentInfo
                                        fbsDecision={fbsDecision}
                                        isComingSoon={isComingSoon}
                                        minMonthlyPayment={minMonthlyPayment}
                                        maxMonthlyPayment={maxMonthlyPayment}
                                        onClick={onClickMonthlyPayment}
                                        className="post-calculator-info"
                                    />
                                    <div className="personalized-terms">
                                        See personalized financing terms
                                    </div>
                                    <a
                                        className="prequal-vsa"
                                        href={prequalAppLink}
                                        data-ignore-anchor-tag-rule
                                    >
                                        <div className="prequal-vsa--label-text">
                                            <div>
                                                Get pre-qualified with no impact
                                                to credit
                                            </div>
                                            <div>
                                                <RightArrowIcon />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )}

                        {(fbsDecision || padTileForPendingDecision) && (
                            <>
                                <hr />
                                <FbsTerms
                                    fbsApplication={fbsApplication}
                                    fbsDecision={fbsDecision}
                                    vehicleData={getVehicleData}
                                    downPayment={
                                        fbsDecision?.downPayment ?? downPayment
                                    }
                                    padForPendingDecision={
                                        padTileForPendingDecision
                                    }
                                    onFbsTermsModalShown={onFbsTermsModalShown}
                                />
                            </>
                        )}
                        {children}
                    </div>
                </TileShell>
            </article>
        </TestConfigHandler>
    );
};

CarTile.propTypes = {
    /** Car whose details are displayed in the tile */
    car: PropTypes.shape({
        stockNumber: PropTypes.number,
        year: PropTypes.number,
        make: PropTypes.string,
        model: PropTypes.string,
        trim: PropTypes.string,
        basePrice: PropTypes.number,
        originalPrice: PropTypes.number,
        mileage: PropTypes.number,
        storeId: PropTypes.number,
        storeName: PropTypes.string,
        stateAbbreviation: PropTypes.string,
        highlights: PropTypes.arrayOf(PropTypes.string),
        isComingSoon: PropTypes.bool,
        exteriorColor: PropTypes.string,
        transferText: PropTypes.string,
        engineType: PropTypes.string,
        transferTimesUnavailable: PropTypes.bool,
    }).isRequired,
    /** Indicates whether car tile image carousel imgs will be lazy-loaded*/
    lazyLoad: PropTypes.bool,
    /** Relative position of the tile */
    position: PropTypes.number,
    /** Indicates whether the favoriting option should be shown */
    showFavorites: PropTypes.bool,
    /** Indicates whether this tile has been viewed */
    viewed: PropTypes.bool,
    /** Indicates whether this car is favorited */
    isFavorite: PropTypes.bool,
    /** Indicates whether this car is disabled */
    isDisabled: PropTypes.bool,
    /** Handler for unfavoriting a car */
    onUnfavorite: PropTypes.func,
    /** Handler for favoriting a car */
    onFavorite: PropTypes.func,
    /** Type of recommendation */
    recommendationType: PropTypes.string,
    /** Function to fetch car images */
    getCarImages: PropTypes.func.isRequired,
    disableScrollNav: PropTypes.bool,
    /** List of actions that can be performed on a car to appear under the three dots menu */
    menuItems: PropTypes.arrayOf(MenuType),
    /** Action to be called out as more important than the others */
    criticalMenuItem: MenuType,
    /** Callback executed when the three dots menu opens */
    onMenuOpen: PropTypes.func,
    /** Callback executed when compare is toggled on a tile */
    handleToggleCompare: PropTypes.func,
    /** Initial car image */
    imageUrl: PropTypes.string.isRequired,
    /** Indicates whether the user has interacted with the budget calculator */
    hasInteraction: PropTypes.bool,
    /** Indicates whether compare mode is on */
    isCompareMode: PropTypes.bool,
    /** Indicates whether this car is selected for compare */
    isCarSelectedForCompare: PropTypes.bool,
    /** The finance decision */
    fbsDecision: FbsDecisionType,
    /** The user's finance application */
    fbsApplication: FbsApplicationType,
    /** Current finance down payment */
    downPayment: PropTypes.number,
    /** Indicates whether this tile should be padded for a pending finance decision to avoid CLS */
    padTileForPendingDecision: PropTypes.bool,
    /** Single monthly payment to display */
    monthlyPayment: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    /** Minimum in a range of monthly payments */
    minMonthlyPayment: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    /** Maximum in a range of monthly payments */
    maxMonthlyPayment: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    onClickMonthlyPayment: PropTypes.func,
    /** Indicates whether an icon should show next to electric vehicles. */
    showElectricVehicleInfo: PropTypes.bool,
    /** Function to execute when the terms modal is shown. */
    onFbsTermsModalShown: PropTypes.func,
    /** Indicates whether the post calculator tile test is enabled. */
    postCalculatorTileTestEnabled: PropTypes.bool,
    /** Name of the page consuming this tile */
    pageName: PropTypes.string,
    /** Function to execute to tag monthly payment info. */
    tagMonthlyPaymentInfo: PropTypes.func,
    /** Indicates whether the CarTile should have a fluid width */
    isFluidWidth: PropTypes.bool,
    /** Children to render with this tile */
    children: PropTypes.node,
    /** Configuration object for various A/B test data */
    testConfig: PropTypes.object,
    /** Whether to make clicking anywhere on the content area go to URL */
    navigateOnContentClick: PropTypes.bool,
    /** Whether or not to render the compact version of the tile */
    isCompact: PropTypes.bool,
};

export default memo(CarTile);
