export const parseImageTag = (imageTag, optionalPrefix = '') => {
    const tag = imageTag.substring(imageTag.indexOf('-') + 1, imageTag.length);
    let newTag = '';

    switch (tag) {
        case 'E-FP3Q':
            newTag = 'Front Passenger 3/4';
            break;

        case 'E-F':
            newTag = 'Front';
            break;

        case 'E-FD3Q':
            newTag = 'Front Driver 3/4';
            break;

        case 'E-DP':
            newTag = 'Driver Profile';
            break;

        case 'E-RD3Q':
            newTag = 'Rear Driver 3/4';
            break;

        case 'E-R':
            newTag = 'Rear';
            break;

        case 'E-RP3Q':
            newTag = 'Rear Passenger 3/4';
            break;

        case 'E-PP':
            newTag = 'Passenger Profile';
            break;

        case 'E-ROOF':
            newTag = 'Roof';
            break;

        case 'E-FP3QTD':
            newTag = 'Front Passenger 3/4 with Top Down';
            break;

        case 'I-DASH':
            newTag = 'Dashboard';
            break;

        case 'I-STEER':
            newTag = 'Steering Wheel & Instrument Cluster (On)';
            break;

        case 'I-FDCOMP':
            newTag = 'Front Driver Compartment';
            break;

        case 'I-FDDOOR':
            newTag = 'Driver Door Panel';
            break;

        case 'I-FDCONT':
            newTag = 'Driver Accessory Controls';
            break;

        case 'I-NAV':
            newTag = 'Navigation (On)';
            break;

        case 'I-BACKUP':
            newTag = 'Back-Up Camera';
            break;

        case 'I-RADIO':
            newTag = 'Radio (On)';
            break;

        case 'I-CLIMATE':
            newTag = 'Climate Controls';
            break;

        case 'I-SHIFTER':
            newTag = 'Shifter';
            break;

        case 'I-USBAUX':
            newTag = 'USB/AUX Ports';
            break;

        case 'I-SUNROOF':
            newTag = 'Sunroof';
            break;

        case 'I-SUNROOF2':
            newTag = 'Second Sunroof';
            break;

        case 'I-RDCOMP':
            newTag = 'Driver Rear Compartment';
            break;

        case 'I-3ROW':
            newTag = 'Third Row Seats';
            break;

        case 'I-CARGO-UP':
            newTag = 'Trunk/Cargo (Seats Up)';
            break;

        case 'I-CARGO-DOWN':
            newTag = 'Trunk/Cargo (Seats Down)';
            break;

        case 'I-FPCOMP':
            newTag = 'Front Passenger Compartment';
            break;

        case 'I-ENGINE':
            newTag = 'Engine';
            break;

        case 'I-KEYS':
            newTag = 'Keys';
            break;

        case 'I-DVD':
            newTag = 'DVD Screen';
            break;

        case 'I-RPCOMP':
            newTag = 'Rear Compartment';
            break;

        case 'E-FDTT':
            newTag = 'Driver Front Tire Tread';
            break;

        case 'E-RDTT':
            newTag = 'Driver Rear Tire Tread';
            break;

        case 'I-SOUND':
            newTag = 'Premium Sound System Logo';
            break;
    }

    if (newTag != '') return optionalPrefix + newTag;
    return newTag;
};
