import './CustomScrollbar.scss';

import classnames from 'classnames';
import {
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { useCombinedRefs } from '@/hooks';

const SCROLL_THUMB_MIN_HEIGHT = 20;
const SCROLL_THUMB_DEFAULT_POS = 2;
const SCROLL_THUMB_HEIGHT_OFFSET = 14; // creates bottom margin => 12px + 2px padding
let timeout = null;

const CustomScrollbar = (
    {
        resizeTarget,
        children,
        className,
        usePadding = true,
        hideScrollbarPlaceholder = false,
        ...restProps
    },
    ref,
) => {
    const [scrollThumbHeight, setScrollThumbHeight] = useState();
    const [isDragging, setIsDragging] = useState(false);

    const scrollThumbHeightOffset = useMemo(
        () => SCROLL_THUMB_HEIGHT_OFFSET - (usePadding ? 0 : 12),
        [usePadding],
    );

    const scrollThumbTop = useRef(SCROLL_THUMB_DEFAULT_POS);
    const lastScrollThumbPosition = useRef(0);

    const scrollHostContainer = useRef();
    const scrollResizeRef = useRef();
    const scrollbarRef = useRef();
    const scrollHostRef = useRef();
    const combinedRefs = useCombinedRefs(ref, scrollHostRef);

    const nothingToScroll = useMemo(() => {
        return (
            !scrollThumbHeight ||
            scrollThumbHeight ===
                scrollHostContainer.current?.offsetHeight -
                    scrollThumbHeightOffset
        );
    }, [scrollThumbHeight, scrollThumbHeightOffset]);

    const scrollThumbStyles = useMemo(() => {
        const styles = {
            height: scrollThumbHeight || SCROLL_THUMB_MIN_HEIGHT,
            transform: `translateY(${scrollThumbTop.current}px)`,
        };
        if (nothingToScroll) {
            styles.opacity = 0;
            styles.pointerEvents = 'none';
        }
        return styles;
    }, [scrollThumbHeight, nothingToScroll]);

    const handleDocumentMouseUp = useCallback(
        (e) => {
            if (isDragging) {
                e.preventDefault();
                setIsDragging(false);
            }
        },
        [isDragging],
    );

    const handleDocumentMouseMove = useCallback(
        (e) => {
            if (isDragging) {
                e.preventDefault();
                e.stopPropagation();
                const scrollHostElement = combinedRefs.current;
                const { scrollHeight, offsetHeight } = scrollHostElement;

                const deltaY = e.clientY - lastScrollThumbPosition.current;
                const percentage = deltaY * (scrollHeight / offsetHeight);

                lastScrollThumbPosition.current = e.clientY;

                scrollThumbTop.current = Math.min(
                    Math.max(
                        SCROLL_THUMB_DEFAULT_POS,
                        scrollThumbTop.current + deltaY,
                    ),
                    offsetHeight - scrollThumbHeight - scrollThumbHeightOffset,
                );

                scrollbarRef.current.firstChild.style.transform = `translateY(${scrollThumbTop.current}px)`;

                scrollHostElement.scrollTop = Math.min(
                    scrollHostElement.scrollTop + percentage,
                    scrollHeight - offsetHeight,
                );
            }
        },
        [isDragging, scrollThumbHeight, scrollThumbHeightOffset],
    );

    const handleScrollThumbMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        lastScrollThumbPosition.current = e.clientY;
        setIsDragging(true);
    };

    const handleScroll = useCallback(() => {
        if (!combinedRefs.current) return;
        const { scrollTop, scrollHeight, offsetHeight } = combinedRefs.current;
        scrollThumbTop.current = Math.min(
            Math.max(
                SCROLL_THUMB_DEFAULT_POS,
                (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) *
                    offsetHeight,
            ),
            offsetHeight - scrollThumbHeight - scrollThumbHeightOffset,
        );
        scrollbarRef.current.firstChild.style.transform = `translateY(${scrollThumbTop.current}px)`;
    }, [scrollThumbHeight, scrollThumbHeightOffset]);

    useLayoutEffect(() => {
        const { current: scrollHostElement } = combinedRefs;
        const resizeObserver = new ResizeObserver(() => {
            const { clientHeight, scrollHeight } = scrollHostElement;
            const thumbHeight = Math.max(
                (clientHeight / scrollHeight) * clientHeight -
                    scrollThumbHeightOffset,
                SCROLL_THUMB_MIN_HEIGHT,
            );

            thumbHeight && setScrollThumbHeight(thumbHeight);
            handleScroll();

            if (timeout) {
                scrollbarRef.current.style.overflow = 'hidden';
                clearTimeout(timeout);
            }
            timeout = setTimeout(
                () => scrollbarRef.current.removeAttribute('style'),
                100,
            );
        });

        resizeObserver.observe(scrollResizeRef.current);
        resizeTarget && resizeObserver.observe(resizeTarget);
        return () => {
            resizeObserver.unobserve(scrollResizeRef.current);
            resizeTarget && resizeObserver.unobserve(resizeTarget);
        };
    }, [handleScroll, resizeTarget, scrollThumbHeightOffset]);

    useEffect(() => {
        combinedRefs.current.addEventListener('scroll', handleScroll, true);
        return () =>
            combinedRefs.current?.removeEventListener(
                'scroll',
                handleScroll,
                true,
            );
    }, [handleScroll]);

    useEffect(() => {
        document.addEventListener('mousemove', handleDocumentMouseMove);
        document.addEventListener('mouseup', handleDocumentMouseUp);
        document.addEventListener('mouseleave', handleDocumentMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleDocumentMouseMove);
            document.removeEventListener('mouseup', handleDocumentMouseUp);
            document.removeEventListener('mouseleave', handleDocumentMouseUp);
        };
    }, [handleDocumentMouseMove, handleDocumentMouseUp]);

    return (
        <div
            ref={scrollHostContainer}
            className={classnames(className, 'scct--scrollhost--container', {
                'scct--scrollhost--no-placeholder':
                    nothingToScroll && hideScrollbarPlaceholder,
            })}
        >
            <div
                ref={combinedRefs}
                className={classnames('scct--scrollhost', {
                    'scct--scrollhost--dragging': isDragging,
                })}
                {...restProps}
            >
                <div
                    ref={scrollResizeRef}
                    className="scct--scrollhost--inner-wrapper"
                >
                    {children}
                </div>
            </div>
            <div
                ref={scrollbarRef}
                className={classnames('scct--scrollhost--scroll-bar', {
                    'scct--scrollhost--scroll-bar--no-padding': !usePadding,
                })}
            >
                <div
                    className={'scct--scrollhost--scroll-thumb'}
                    style={scrollThumbStyles}
                    onMouseDown={handleScrollThumbMouseDown}
                />
            </div>
        </div>
    );
};

export default memo(forwardRef(CustomScrollbar));
