import './Ribbon.scss';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import InfoIcon from '@/images/badge-icons/InfoIcon';

const Ribbon = ({
    content: { text, type },
    className,
    ribbonStyle,
    style,
    infoAction,
}) => {
    return (
        <div
            className={classnames(
                'scct--ribbon',
                `scct--ribbon--${type}`,
                ribbonStyle,
                className,
            )}
            style={style}
            onClick={infoAction}
            tabIndex={infoAction ? 0 : -1}
            role={infoAction ? 'button' : null}
        >
            {ribbonStyle == 'checked' && <CheckmarkIcon />}
            {text}
            {infoAction && <InfoIcon />}
        </div>
    );
};

const CheckmarkIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="10"
            viewBox="0 0 12 10"
            fill="none"
        >
            <path
                d="M3.81353 7.69691L0.968732 4.7946L0 5.77596L3.81353 9.66659L12 1.31461L11.0381 0.333252L3.81353 7.69691Z"
                fill="#2A343D"
            />
        </svg>
    );
};

Ribbon.propTypes = {
    content: PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
    ribbonStyle: PropTypes.func.string,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default memo(Ribbon);
