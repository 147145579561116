import AdvancedFeaturesIcon from '@/images/badge-icons/AdvancedFeaturesIcon';
import FuelEfficientIcon from '@/images/badge-icons/FuelEfficientIcon';
import LowMilesIcon from '@/images/badge-icons/LowMilesIcon';
import NewTireIcon from '@/images/badge-icons/NewTireIcon';
import PremiumAudioIcon from '@/images/badge-icons/PremiumAudioIcon';
import SingleOwnerIcon from '@/images/badge-icons/SingleOwnerIcon';

const badges = {
    singleOwner: {
        icon: <SingleOwnerIcon />,
        message: 'Single Owner',
    },
    allNewTires: {
        icon: <NewTireIcon />,
        message: 'All New Tires',
    },
    newPairTires: {
        icon: <NewTireIcon />,
        message: 'New Pair of Tires',
    },
    lowMiles: {
        icon: <LowMilesIcon />,
        message: 'Low Miles',
    },
    lowMilesPerYear: {
        icon: <LowMilesIcon />,
        message: 'Low Miles Per Year',
    },
    fuelEfficient: {
        icon: <FuelEfficientIcon />,
        message: 'Fuel Efficient',
    },
    advancedFeatures: {
        icon: <AdvancedFeaturesIcon />,
        message: 'Advanced Features',
    },
    premiumAudio: {
        icon: <PremiumAudioIcon />,
        message: 'Premium Audio',
    },
};

export default badges;
