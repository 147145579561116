import { useEffect, useRef } from 'react';

export const useEventListener = (eventName, ref, handler) => {
    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const el = ref.current;
        const isSupported = el && el.addEventListener;
        if (!isSupported) return;

        const eventListener = (event) => {
            if (savedHandler.current) {
                savedHandler.current(event);
            }
        };
        el && el.addEventListener(eventName, eventListener);

        return () => {
            el && el.removeEventListener(eventName, eventListener);
        };
    }, [eventName, ref]);
};
