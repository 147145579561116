import PropTypes from 'prop-types';

export default PropTypes.shape({
    downPayment: PropTypes.number,
    conditionAmount: PropTypes.number,
    conditionAmountFormatted: PropTypes.string,
    decision: PropTypes.string,
    lenderName: PropTypes.string,
    taxesAndFees: PropTypes.number,
    taxesAndFeesFormatted: PropTypes.string,
    taxesAndFeesRounded: PropTypes.number,
    totalFinanceAmount: PropTypes.number,
    terms: PropTypes.arrayOf(
        PropTypes.shape({
            aprDisplayType: PropTypes.string,
            displayApr: PropTypes.string,
            displayMonthlyPayment: PropTypes.string,
            monthlyPaymentDisplayType: PropTypes.string,
            minTermRate: PropTypes.shape({
                apr: PropTypes.number,
                monthlyPayment: PropTypes.number,
            }),
            maxTermRate: PropTypes.shape({
                apr: PropTypes.number,
                monthlyPayment: PropTypes.number,
            }),
        }),
    ),
});
