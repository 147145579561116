import './Label.scss';

import classnames from 'classnames';
import { memo, useCallback } from 'react';

import { useModals } from '@/hooks/useModals';

import FbsTermsModal from '../FbsTermsModal/FbsTermsModal';
import InfoIcon from '../InfoButton/InfoIcon';

const Label = ({
    content: { text, type },
    className,
    fbsDecision,
    fbsApplication,
    vehicleData,
    isDisabled,
    downPayment,
    onFbsTermsModalShown,
    ...props
}) => {
    const { openModal } = useModals();

    const handleModal = useCallback(
        (e) => {
            e.preventDefault();
            openModal(
                <FbsTermsModal
                    fbsApplication={fbsApplication}
                    fbsDecision={fbsDecision}
                    vehicleData={vehicleData}
                    downPayment={downPayment}
                    onModalShown={onFbsTermsModalShown}
                />,
            );
        },
        [openModal, fbsApplication, fbsDecision, vehicleData, downPayment],
    );

    return (
        <button
            className={classnames(
                'scct--label',
                `scct--label--${!isDisabled ? type : 'disabled'}`,
                className,
            )}
            {...props}
            onClick={handleModal}
            tabIndex={'0'}
        >
            {text}
            <div className="scct--label--icon">
                <InfoIcon />
            </div>
        </button>
    );
};
export default memo(Label);
