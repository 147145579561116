import { formatCurrency } from '@/utilities';

export default {
    approved: ({ fbsDecision, downPayment }) => {
        const carMonthlyPayment =
            fbsDecision.terms[0]?.maxTermRate?.monthlyPayment || 0;
        if (carMonthlyPayment === 0) {
            return {
                text: 'Buy without financing',
                subText: 'Your down payment exceeds our no-haggle price.',
                type: 'notneeded',
                hasTerms: false,
            };
        }

        return {
            text: `Pre-qualified with ${formatCurrency(fbsDecision.conditionAmountRounded + downPayment)} down`,
            subText: null,
            type: 'preapproved',
            hasTerms: true,
        };
    },
    countered: ({ fbsDecision, downPayment }) => {
        if (
            fbsDecision &&
            fbsDecision.lenderName === 'Cash' &&
            fbsDecision.totalFinanceAmount === 0
        ) {
            // TODO: This is a temporary way of determining vehicles where pre-approval is unavailable.  FBS will be making a change to determine it on their side and pass a different status instead.
            return {
                text: `Pre-qualification unavailable on this car`,
                subText:
                    "We weren't able to pre-approve you for financing at this time.",
                type: 'declined',
                hasTerms: false,
            };
        }

        return {
            text: `Pre-qualified with ${formatCurrency(fbsDecision?.conditionAmountRounded + downPayment)} down`,
            subText: null,
            type: 'additionaldown',
            hasTerms: true,
        };
    },
    declined: ({ downPayment, basePrice }) => {
        // TODO: Temporary until FBS fixes Exeter handling of downpayments that exceed the base price.
        if (downPayment >= basePrice) {
            return {
                text: 'Buy without financing',
                subText: 'Your down payment exceeds our no-haggle price.',
                type: 'notneeded',
                hasTerms: false,
            };
        }

        return {
            text: `Pre-qualification unavailable on this car`,
            subText:
                "We weren't able to pre-approve you for financing at this time.",
            type: 'declined',
            hasTerms: false,
        };
    },
    default: () => ({
        text: 'Information unavailable',
        subText: 'A technical error occurred on our end. Check back later.',
        type: 'noinfo',
        hasTerms: false,
    }),
};
