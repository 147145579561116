import { HznButton } from '@horizon/button';
import { defineHorizonElement } from '@horizon/common/helpers';
import { registerIcon } from '@horizon/icons';
import { HznIcon } from '@horizon/icons';
import {
    CheckIcon,
    LeafIcon,
    LightningBoltIcon,
    PlugIcon,
} from '@horizon/icons/individual/library';
import { HznLoading } from '@horizon/loading';
import { HznSelect } from '@horizon/select';

registerIcon(CheckIcon);
registerIcon(LeafIcon);
registerIcon(LightningBoltIcon);
registerIcon(PlugIcon);

defineHorizonElement('scct-hzn-button', HznButton);
defineHorizonElement('scct-hzn-loading', HznLoading);
defineHorizonElement('scct-hzn-select', HznSelect);
defineHorizonElement('scct-hzn-icon', HznIcon);
