import './TabBar.scss';

import classNames from 'classnames';
import { Children, cloneElement, memo } from 'react';

// Imported and modified from acquisitions-ui to remove nanoid dependency

const TabBar = ({ className, id, children }) => {
    const numTabs = Children.toArray(children).length;

    return (
        <div
            id={id}
            className={classNames(className, 'scct--tab-bar-container')}
        >
            {Children.map(children, (child, index) =>
                cloneElement(child, { index, numTabs }),
            )}
        </div>
    );
};

export default memo(TabBar);
