import './PriceMilesInfo.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { formatCurrency } from '@/utilities';

const PriceMilesInfo = ({
    basePrice,
    originalPrice,
    mileage,
    children,
    hasPriceDrop,
    isCompact,
}) => {
    const formattedPrice = basePrice ? `${formatCurrency(basePrice)}*` : 'N/A';
    const formattedOriginalPrice =
        hasPriceDrop && originalPrice ? formatCurrency(originalPrice) : null;
    const roundedMileage = Math.ceil(mileage / 1000);
    const visibleMileage = `${roundedMileage}K mi`;
    const srMileage = `${roundedMileage},000 miles`;

    return (
        <div className="scct--price-miles-info--price-wrapper">
            {!isCompact ? (
                <div className="scct--price-drop-original-price">
                    {formattedOriginalPrice ? (
                        <>
                            was&nbsp;
                            <span className="scct--price-drop-original-price-amount">
                                {formattedOriginalPrice}
                            </span>
                            *
                        </>
                    ) : (
                        <>&nbsp;</>
                    )}
                </div>
            ) : null}
            <div
                className={classNames('scct--price-miles-info', {
                    'scct--price-miles-info--has-price-drop': hasPriceDrop,
                })}
            >
                {hasPriceDrop ? (
                    <span className="scct--price-drop-icon" title="Price drop">
                        <svg
                            width="23"
                            height="24"
                            viewBox="0 0 23 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M22.3414 14.1818C22.0428 13.3321 21.2734 12.7809 20.3663 12.7809H18.4371V3.11196C18.4371 1.40096 17.0361 0 15.3251 0H7.13756C5.42655 0 4.02559 1.40096 4.02559 3.11196V12.7809H2.09641C1.18923 12.7809 0.419852 13.3321 0.121287 14.1818C-0.177277 15.0316 0.0868379 15.9502 0.787316 16.5129L9.28492 23.311C9.85909 23.7703 10.5481 24 11.2256 24C11.9031 24 12.5921 23.7703 13.1663 23.311L21.6639 16.5129C22.3643 15.9502 22.6285 15.0316 22.3299 14.1818H22.3414ZM12.0409 16.0421V16.8919C12.0409 17.3397 11.6734 17.7072 11.2256 17.7072C10.7777 17.7072 10.4103 17.3397 10.4103 16.8919V16.0421C9.17009 15.9158 8.14808 14.9742 7.97583 13.7225C7.91842 13.2746 8.22847 12.8612 8.66483 12.8038C9.11268 12.7464 9.52607 13.0565 9.58349 13.4928C9.65239 14.0325 10.1232 14.4344 10.6629 14.4344H11.7653C12.3624 14.4344 12.8562 13.9407 12.8562 13.3435C12.8562 12.8727 12.5577 12.4593 12.1098 12.31L9.79019 11.5407C8.67631 11.1732 7.9299 10.1282 7.9299 8.95694C7.9299 7.5445 9.00933 6.39617 10.3873 6.25837V5.40861C10.3873 4.96077 10.7548 4.5933 11.2026 4.5933C11.6505 4.5933 12.0179 4.96077 12.0179 5.40861V6.25837C13.2466 6.38469 14.2801 7.32632 14.4524 8.57799C14.5098 9.02584 14.1998 9.43923 13.7634 9.49665C13.3155 9.56555 12.9021 9.24402 12.8447 8.80766C12.7643 8.26794 12.305 7.86603 11.7653 7.86603H10.6629C10.0658 7.86603 9.57201 8.35981 9.57201 8.95694C9.57201 9.42775 9.87057 9.84115 10.3184 9.99043L12.638 10.7598C13.7519 11.1273 14.4983 12.1722 14.4983 13.3435C14.4983 14.756 13.4189 15.9043 12.0409 16.0421Z"
                                fill="#1B6B00"
                            />
                        </svg>
                    </span>
                ) : null}
                <span className="scct--price-miles-info--price-container">
                    {hasPriceDrop && !isCompact ? (
                        <span className="scct--price-miles-info--text-before-price">
                            now&nbsp;
                        </span>
                    ) : null}
                    <span className="scct--price-miles-info--price">
                        {formattedPrice}
                    </span>
                    {isCompact && formattedOriginalPrice ? (
                        <span className="scct--price-drop-original-price">
                            <span
                                className="scct--price-drop-original-price-amount"
                                aria-label={`was ${formattedOriginalPrice}`}
                            >
                                {formattedOriginalPrice}
                            </span>
                            *
                        </span>
                    ) : null}
                </span>
                <span className="scct--price-miles-info--divider" />
                <span
                    className="scct--price-miles-info--miles"
                    title={visibleMileage}
                >
                    <span aria-hidden="true">{visibleMileage}</span>
                    <span className="scct--sr-only">{srMileage}</span>
                </span>
                {children}
            </div>
        </div>
    );
};

PriceMilesInfo.propTypes = {
    basePrice: PropTypes.number,
    originalPrice: PropTypes.number,
    mileage: PropTypes.number.isRequired,
    children: PropTypes.node,
    hasPriceDrop: PropTypes.bool,
    /** Whether or not to render the compact version of the tile */
    isCompact: PropTypes.bool,
};

export default memo(PriceMilesInfo);
