const LowMilesIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        aria-hidden="true"
    >
        <path fill="none" d="M0 0h64v64H0z" />
        <path d="m32.66 57.33 12.72-12.72a.93.93 0 0 0-.66-1.61H38.6v-3.37a.93.93 0 1 0-1.87 0v4.32a.93.93 0 0 0 .93.93h4.8L32 55.35 21.53 44.88h4.8a.93.93 0 0 0 .93-.93v-4.32a.93.93 0 1 0-1.87 0V43h-6.11a.93.93 0 0 0-.86.58.91.91 0 0 0-.07.36.93.93 0 0 0 .27.66l12.72 12.73a.93.93 0 0 0 1.32 0ZM38.77 25.74a3.06 3.06 0 0 0 2.64-1.2 5.31 5.31 0 0 0 .88-3.2 5.31 5.31 0 0 0-.88-3.2A3.06 3.06 0 0 0 38.77 17a3.06 3.06 0 0 0-2.64 1.2 5.31 5.31 0 0 0-.88 3.2 5.31 5.31 0 0 0 .88 3.2 3.06 3.06 0 0 0 2.64 1.14Zm-1.26-6.44a1.4 1.4 0 0 1 2.51 0 4.51 4.51 0 0 1 .4 2.05 4.51 4.51 0 0 1-.4 2.06 1.4 1.4 0 0 1-2.51 0 4.5 4.5 0 0 1-.4-2.06 4.49 4.49 0 0 1 .4-2.05Zm-12.28 6.44a3.06 3.06 0 0 0 2.64-1.2 5.31 5.31 0 0 0 .88-3.2 5.31 5.31 0 0 0-.88-3.2A3.06 3.06 0 0 0 25.23 17a3.06 3.06 0 0 0-2.64 1.2 5.31 5.31 0 0 0-.88 3.2 5.31 5.31 0 0 0 .88 3.2 3.06 3.06 0 0 0 2.64 1.14ZM24 19.3a1.4 1.4 0 0 1 2.51 0 4.51 4.51 0 0 1 .4 2.05 4.51 4.51 0 0 1-.4 2.06 1.4 1.4 0 0 1-2.51 0 4.5 4.5 0 0 1-.4-2.06 4.49 4.49 0 0 1 .4-2.05Zm-12.41 6.3h1.82v-8.5H10v1.62h1.62Zm46.49-15H5.92a2 2 0 0 0-2 2v17.52a2 2 0 0 0 2 2h52.16a2 2 0 0 0 2-2V12.58a2 2 0 0 0-2-1.97ZM5.92 30.12V12.58h11.56v17.54Zm13.53 0V12.58H31v17.54Zm13.53 0V12.58h11.57v17.54Zm17.77-3.22v-1.46h3.1v4.68h-1.64V26.9Zm7-5.1h-10.9a.92.92 0 0 0-.33.07v-1.33a.94.94 0 0 0 .33.07h10.91a.94.94 0 0 0 .33-.07v1.33a.92.92 0 0 0-.33-.07Zm-2.69-6.29v1.53h-5.4v-1.26l2.41-3.2h2.07c-.46.62-2.1 2.94-2.1 2.94Zm6-9.12H2.93A2.94 2.94 0 0 0 0 9.35v24a2.94 2.94 0 0 0 2.93 3h58.14a3 3 0 0 0 2.93-3v-24a2.94 2.94 0 0 0-2.93-2.95Zm1 26.94a1 1 0 0 1-1 1H2.93a1 1 0 0 1-1-1v-24a1 1 0 0 1 1-1h58.14a1 1 0 0 1 1 1Z" />
    </svg>
);

export default LowMilesIcon;
