import './ElectricVehicleTypeInfo.scss';

import PropTypes from 'prop-types';
import { memo } from 'react';

import { useModals } from '@/hooks/useModals';

import ElectricVehicleTypeModal from './ElectricVehicleTypeModal';

function getIconName(type) {
    switch (type) {
        case 'Hybrid':
            return 'leaf';
        case 'Plug-In Hybrid':
            return 'plug';
        case 'Electric':
            return 'lightning-bolt';
        default:
            return null;
    }
}

const ElectricVehicleTypeInfo = ({ engineTypeIcon, isCompact }) => {
    const iconVariant = isCompact ? 'stroked' : 'filled';
    const iconName = getIconName(engineTypeIcon);

    const { openModal } = useModals();
    const showModal = () => {
        openModal(<ElectricVehicleTypeModal />);
    };

    if (!iconName) {
        return null;
    }

    return (
        <button
            className="scct-electric-icon"
            type="button"
            onClick={showModal}
            aria-label={`${engineTypeIcon} vehicle`}
        >
            <scct-hzn-icon name={iconName} variant={iconVariant} />
        </button>
    );
};

ElectricVehicleTypeInfo.propTypes = {
    /**  Svg that indicates what kind of electric vehicle it is */
    engineTypeIcon: PropTypes.string,
    /** Whether or not to render the compact version of the tile */
    isCompact: PropTypes.bool,
};

export default memo(ElectricVehicleTypeInfo);
