const PremiumAudioIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        aria-hidden="true"
        {...props}
    >
        <path fill="none" d="M0 0h64v64H0z" />
        <path d="M9.54 15.42a18.44 18.44 0 0 1 29.94-1.86 18.26 18.26 0 0 1 4.12 11.17 1 1 0 1 0 2.06 0 20.3 20.3 0 0 0-4.58-12.42 20.5 20.5 0 0 0-33.3 2.1A20.5 20.5 0 0 0 30 45a1 1 0 1 0-.48-2A18.42 18.42 0 0 1 9.54 15.42zm10.24 4.36a7.63 7.63 0 1 0 10.8 0 7.64 7.64 0 0 0-10.8 0m9.34 9.34a5.57 5.57 0 1 1 0-7.88 5.57 5.57 0 0 1 0 7.88M44 11.74a23.17 23.17 0 0 1 4.3 14.4 1 1 0 0 0 2.05.1 25.27 25.27 0 0 0-4.14-14.92q-.5-.76-1.05-1.48l.6-.6a3.26 3.26 0 0 0-4.62-4.6l-.6.6a25.15 25.15 0 0 0-30.68 0l-.6-.6a3.26 3.26 0 0 0-4.6 4.6l.58.6a25.14 25.14 0 0 0 0 30.68l-.6.6a3.26 3.26 0 0 0 0 4.6 3.23 3.23 0 0 0 2.3 1 3.24 3.24 0 0 0 2.32-1l.6-.6.6.46 1.3.9a25.28 25.28 0 0 0 15 3.82 1 1 0 0 0-.15-2.06 23.17 23.17 0 0 1-14.5-4q-.9-.6-1.72-1.3A1 1 0 0 0 9 43l-1.2 1.26a1.2 1.2 0 0 1-1.7-1.7l1.24-1.23a1 1 0 0 0 .07-1.4 23.07 23.07 0 0 1 0-29.5A1 1 0 0 0 7.35 9L6.1 7.8a1.2 1.2 0 0 1 1.7-1.7L9 7.33a1 1 0 0 0 1.4.06 23.06 23.06 0 0 1 29.5 0 1 1 0 0 0 1.4-.06l1.23-1.24a1.2 1.2 0 0 1 1.7 0 1.2 1.2 0 0 1 0 1.7L43 9a1 1 0 0 0 0 1.42q.37.44.7.9z" />
        <path d="M63.32 35.4 45.1 28.73a1 1 0 0 0-1.4 1v17.14a8.62 8.62 0 0 0-5.68-2c-4.27 0-7.75 2.8-7.75 6.25s3.47 6.25 7.75 6.25 7.75-2.8 7.75-6.25V31.2l16.16 5.88v16.45a8.62 8.62 0 0 0-5.68-2c-4.27 0-7.75 2.8-7.75 6.26S52 64 56.25 64 64 61.2 64 57.75v-21.4a1 1 0 0 0-.68-.96zM38 55.3c-3.13 0-5.68-1.9-5.68-4.2s2.55-4.2 5.68-4.2 5.68 1.9 5.68 4.2-2.52 4.2-5.68 4.2zm18.22 6.64c-3.13 0-5.68-1.88-5.68-4.2s2.55-4.18 5.68-4.18 5.68 1.88 5.68 4.2-2.52 4.18-5.65 4.18z" />
    </svg>
);

export default PremiumAudioIcon;
