import './StoreTransferInfo.scss';

import { memo } from 'react';

const StoreTransferInfo = ({
    transferText,
    storeName,
    storeState,
    transferTimeMin,
    transferTimeMax,
    transferTimesUnavailable,
    children,
}) => {
    const addDays = (days) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    };

    const getTransferTime = () => {
        if (transferTimesUnavailable) {
            return '| Est. arrival unavailable';
        }

        if (!transferTimeMin || !transferTimeMax) {
            return '';
        }

        const minDate = addDays(transferTimeMin);
        const maxDate = addDays(transferTimeMax);

        //Add 1 to the date.getMonth() since it’s 0-based indexing
        const startDate = `${minDate.getMonth() + 1}/${minDate.getDate()}`;
        const endDate = `${maxDate.getMonth() + 1}/${maxDate.getDate()}`;

        return `| Est. arrival ${startDate}-${endDate}`;
    };

    const transferTimeText = getTransferTime();

    return (
        <p className="scct--store-transfer-info">
            <span
                className="scct--store-transfer-info--transfer"
                title={`${transferText} ${transferTimeText} CarMax ${storeName}, ${storeState}`}
            >
                {transferText} {transferTimeText}
                <br />
                {`CarMax ${storeName}, ${storeState}`}
            </span>
            {children}
        </p>
    );
};

export default memo(StoreTransferInfo);
