import './FbsPriceTable.scss';

import { Calculator } from '@kmx/online-finance-helpers';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

import { formatCurrency } from '@/utilities';

import FbsApplicationType from '../CarTile/fbsApplication.proptypes';
import FbsDecisionType from '../CarTile/fbsDecision.proptypes';

const FbsPriceTable = ({
    fbsApplication: { tradeInBreakdown },
    fbsDecision: { conditionAmountRounded, taxesAndFeesRounded },
    vehicleData: { basePrice, feesList },
    downPayment = 0,
}) => {
    const estimatedFees =
        taxesAndFeesRounded ||
        Calculator.lookupFeesByVehiclePrice(feesList, basePrice);
    const formattedVehiclePrice = useMemo(() => {
        const price = basePrice;
        return `${price ? formatCurrency(price) : 'N/A'}`;
    }, [basePrice]);
    const formattedFees = formatCurrency(estimatedFees);
    const formattedDownpayment = formatCurrency(
        downPayment + conditionAmountRounded,
    );
    const formattedFinalPrice = formatCurrency(
        basePrice + estimatedFees - (downPayment + conditionAmountRounded),
    );
    const formattedFinalPriceWithTradeIn = formatCurrency(
        basePrice +
            estimatedFees -
            (downPayment + conditionAmountRounded) -
            (tradeInBreakdown.negativeEquity +
                tradeInBreakdown.appliedPositiveEquity),
    );

    const showTradeInEquity = useMemo(() => {
        if (!tradeInBreakdown) return false;
        return !Object.values(tradeInBreakdown).every(
            (x) => x === null || x === 0,
        );
    }, [tradeInBreakdown]);

    /*
        if negativeEquity is nonzero, show negativeEquity
        if positiveEquity is >=0, show appliedPositiveEquity (i.e its possible to get a positive equity of $0)
    */
    const formattedTradeInEquity = useMemo(() => {
        const { appliedPositiveEquity, negativeEquity } = tradeInBreakdown;

        if (negativeEquity !== 0)
            return `${formatCurrency(Math.abs(negativeEquity))}`;

        return `${formatCurrency(-appliedPositiveEquity)}`;
    }, [tradeInBreakdown]);

    return (
        <table className="fbs-terms--price-table">
            <tbody>
                <tr>
                    <td>Vehicle price</td>
                    <td aria-describedby="vehicle-price-disclaimer">
                        {formattedVehiclePrice}
                    </td>
                </tr>
                <tr>
                    <td>Est. tax, title &#38; fees</td>
                    <td>{formattedFees}</td>
                </tr>
                {showTradeInEquity && (
                    <tr>
                        <td>Trade-in equity</td>
                        <td>{formattedTradeInEquity}</td>
                    </tr>
                )}

                <tr>
                    <td>Down payment</td>
                    <td>- {formattedDownpayment}</td>
                </tr>
                <tr className="price-table-final">
                    <td>Total finance amount</td>
                    <td>
                        {showTradeInEquity
                            ? formattedFinalPriceWithTradeIn
                            : formattedFinalPrice}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

FbsPriceTable.propTypes = {
    /** The user's finance application */
    fbsApplication: FbsApplicationType.isRequired,
    /** The finance decision */
    fbsDecision: FbsDecisionType.isRequired,
    /** Vehicle data */
    vehicleData: PropTypes.shape({
        basePrice: PropTypes.number,
        feesList: PropTypes.arrayOf(
            PropTypes.shape({
                minimum: PropTypes.number,
                maximum: PropTypes.number,
                estimatedFeesMinimum: PropTypes.number,
                estimatedFeesMaximum: PropTypes.number,
                details: PropTypes.arrayOf(
                    PropTypes.shape({
                        salesTax: PropTypes.arrayOf(PropTypes.string),
                        title: PropTypes.arrayOf(PropTypes.string),
                        registration: PropTypes.arrayOf(PropTypes.string),
                        plateTransfer: PropTypes.arrayOf(PropTypes.string),
                        additionalFees: PropTypes.arrayOf(PropTypes.string),
                        total: PropTypes.string,
                        highFeeEstimate: PropTypes.number,
                    }),
                ),
            }),
        ),
    }).isRequired,
    /** User's finance downpayment */
    downPayment: PropTypes.number,
};

export default memo(FbsPriceTable);
