const FuelEfficientIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 64 64"
        aria-hidden="true"
    >
        <path fill="none" d="M0 0h64v64H0z" />
        <path d="m9 29.33-2.74 3.16 4.37 3.85 2.86-3.18-.87-.77-2 2.2-.87-.77 1.69-2-.87-.77-1.69 2-.88-.77 1.9-2.18Zm48.3 4.34.89-.75-2.64-3-4.29 3.87.82 1 1.69-1.52 1.66 1.91.89-.75-1.66-1.93.91-.84ZM32 15.47A37.88 37.88 0 0 0 .61 32.13a3.53 3.53 0 0 0 1.15 5l7.18 4.14a3.51 3.51 0 0 0 4.64-1A22.65 22.65 0 0 1 32 30.78a22.33 22.33 0 0 1 10.22 2.44 1.1 1.1 0 0 0 1-2A24.52 24.52 0 0 0 32 28.58 24.84 24.84 0 0 0 11.78 39a1.33 1.33 0 0 1-1.75.39l-7.17-4.12a1.31 1.31 0 0 1-.63-.85 1.33 1.33 0 0 1 .2-1.05 35.72 35.72 0 0 1 8.22-8.59l2.83 4a1.1 1.1 0 0 0 1.8-1.26l-2.83-4A35.64 35.64 0 0 1 30.9 17.7v4.78a1.1 1.1 0 0 0 2.2 0V17.7a35.64 35.64 0 0 1 18.46 5.82l-2.83 4a1.1 1.1 0 1 0 1.8 1.26l2.83-4a35.74 35.74 0 0 1 8.22 8.59 1.33 1.33 0 0 1 .2 1.05 1.31 1.31 0 0 1-.63.85L54 39.41a1.32 1.32 0 0 1-1.78-.41 25 25 0 0 0-3.43-3.89l1.05-1.25a1.1 1.1 0 0 0-1.68-1.42l-1.74 2.06-11.26 13.4a6.3 6.3 0 1 0 1.68 1.41l10.52-12.5a22.73 22.73 0 0 1 3.06 3.48 3.51 3.51 0 0 0 4.64 1l7.18-4.14a3.53 3.53 0 0 0 1.15-5A37.88 37.88 0 0 0 32 15.47Zm0 42a4.12 4.12 0 1 1 4.12-4.12A4.12 4.12 0 0 1 32 57.48ZM60.79 14.74l-7.37 2A1.17 1.17 0 0 0 54 19l7.37-2a1.17 1.17 0 1 0-.61-2.27ZM45.4 14a1.17 1.17 0 0 0 .83-1.44l-2-7.37A1.17 1.17 0 1 0 42 5.8l2 7.37a1.17 1.17 0 0 0 1.4.83Zm9.24-7.7a1.17 1.17 0 0 0-1.6.43l-3.82 6.61a1.17 1.17 0 0 0 2 1.17l3.85-6.61a1.18 1.18 0 0 0-.43-1.6Z" />
    </svg>
);

export default FuelEfficientIcon;
