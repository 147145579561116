import {
    autoUpdate,
    FloatingFocusManager,
    FloatingPortal,
    offset,
    useDismiss,
    useFloating,
    useInteractions,
    useListNavigation,
    useRole,
} from '@floating-ui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback, useRef, useState } from 'react';

import ActionMenuPopover from './ActionMenuPopover';
import ActionMenuTrigger from './ActionMenuTrigger';
import MenuType from './menu.proptypes';

const ActionMenu = memo(function ActionMenu({
    criticalMenuItem,
    handleToggleCompare,
    handleToggleMenu,
    isCarSelectedForCompare,
    isCompareMode,
    menuContainerRef,
    menuItems,
}) {
    const menuListRef = useRef([]);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeMenuIndex, setActiveMenuIndex] = useState(null);

    const handleOpenChange = useCallback(
        (open) => {
            setIsMenuOpen(open);
            handleToggleMenu?.(open);
        },
        [handleToggleMenu],
    );

    const handleClosePopover = useCallback(() => {
        handleOpenChange(false);
    }, [handleOpenChange]);

    const { refs, floatingStyles, context } = useFloating({
        placement: 'bottom-end',
        middleware: [
            offset({
                mainAxis: 8,
            }),
        ],
        open: isMenuOpen,
        onOpenChange: handleOpenChange,
        whileElementsMounted: autoUpdate,
    });

    const dismiss = useDismiss(context, {
        enabled: !isCompareMode,
    });

    const listNavigation = useListNavigation(context, {
        enabled: !isCompareMode,
        listRef: menuListRef,
        activeIndex: activeMenuIndex,
        onNavigate: setActiveMenuIndex,
    });

    const role = useRole(context, {
        enabled: !isCompareMode,
        role: 'menu',
    });

    const { getReferenceProps, getFloatingProps, getItemProps } =
        useInteractions([dismiss, listNavigation, role]);

    return (
        <>
            <ActionMenuTrigger
                className={classNames({
                    selected: isCarSelectedForCompare,
                })}
                getReferenceProps={getReferenceProps}
                handleSetIsPopoverOpen={handleOpenChange}
                handleToggleCompare={handleToggleCompare}
                isCarSelectedForCompare={isCarSelectedForCompare}
                isCompareMode={isCompareMode}
                isPopoverOpen={isMenuOpen}
                ref={refs.setReference}
            />
            {!isCompareMode && isMenuOpen ? (
                <FloatingPortal root={menuContainerRef}>
                    <FloatingFocusManager context={context} initialFocus={-1}>
                        <ActionMenuPopover
                            activeIndex={activeMenuIndex}
                            criticalMenuItem={criticalMenuItem}
                            getFloatingProps={getFloatingProps}
                            getItemProps={getItemProps}
                            handleClosePopover={handleClosePopover}
                            listRef={menuListRef}
                            menuItems={menuItems}
                            ref={refs.setFloating}
                            style={floatingStyles}
                        />
                    </FloatingFocusManager>
                </FloatingPortal>
            ) : null}
        </>
    );
});

ActionMenu.propTypes = {
    criticalMenuItem: MenuType,
    handleToggleCompare: PropTypes.func,
    handleToggleMenu: PropTypes.func,
    isCarSelectedForCompare: PropTypes.bool,
    isCompareMode: PropTypes.bool,
    menuContainerRef: PropTypes.shape({
        current: PropTypes.instanceOf(HTMLElement),
    }),
    menuItems: PropTypes.arrayOf(MenuType).isRequired,
};

export default ActionMenu;
