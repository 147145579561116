import './FbsAvailableTerms.scss';

import { memo, useCallback, useRef, useState } from 'react';

import { useEventListener, useGetBreakPoints } from '@/hooks';
import { formatCurrency } from '@/utilities';

import Tab from '../TabBar/Tab';
import TabBar from '../TabBar/TabBar';

const FbsAvailableTerms = ({ fbsDecision }) => {
    const breakpoints = useGetBreakPoints();
    const isMobile = breakpoints.isPhone || breakpoints.isTablet;
    const [selectedTerm, setSelectedTerm] = useState(fbsDecision?.terms[0]);
    const dropdownRef = useRef();

    const handleDropdownChange = useCallback(
        (e) => {
            const value = e.target.value;
            const term = fbsDecision?.terms?.find(
                (fbsTerm) => fbsTerm.length == value,
            );
            setSelectedTerm(term);
        },
        [fbsDecision?.terms],
    );

    useEventListener('change', dropdownRef, handleDropdownChange);

    const getTermTabs = () => {
        if (fbsDecision?.terms && fbsDecision?.terms.length > 1) {
            const sortedTerms = [...fbsDecision.terms].sort(
                (a, b) => a.length - b.length,
            );

            if (isMobile) {
                const simplifiedTerms = sortedTerms.map((term) => {
                    return {
                        label: `${term.length} MO.`,
                        key: term.length,
                        value: term.length,
                    };
                });
                return (
                    <scct-hzn-select
                        ref={dropdownRef}
                        hide-label
                        compact
                        class="fbs-term-dropdown"
                        value={selectedTerm.length}
                    >
                        Available Term Lengths
                        {simplifiedTerms.map((term) => (
                            <option key={term.value} value={term.value}>
                                {term.label}
                            </option>
                        ))}
                    </scct-hzn-select>
                );
            }
            return (
                <TabBar className="fbs-term-tabbar">
                    {sortedTerms.map((term) => {
                        return (
                            <Tab
                                key={term.length}
                                id={term.length}
                                onClick={() => setSelectedTerm(term)}
                                active={selectedTerm === term}
                                className={'fbs-tab-button'}
                            >
                                {`${term.length} MO.`}
                            </Tab>
                        );
                    })}
                </TabBar>
            );
        }
        return <></>;
    };

    const getSelectedTermRate = () => {
        if (selectedTerm) {
            const maxPayment = formatCurrency(
                selectedTerm.maxTermRate.monthlyPayment,
            );
            const minPayment = formatCurrency(
                selectedTerm.minTermRate.monthlyPayment,
            );
            const termRate =
                (maxPayment == minPayment
                    ? maxPayment
                    : `${minPayment}-${maxPayment}`) + '/month';

            return <div className="fbs-selected-term-rate">{termRate}</div>;
        }
        return <></>;
    };

    const getSelectedTermAPR = () => {
        if (selectedTerm) {
            const maxApr = selectedTerm.maxTermRate.apr;
            const minApr = selectedTerm.minTermRate.apr;
            return (
                <div className="scct--fbs-terms--rate-term">
                    {minApr === maxApr
                        ? `${minApr}% APR`
                        : `${minApr}%-${maxApr}% APR`}
                    <span className="scct--fbs-terms--bullet">&#8226;</span>
                    {selectedTerm.length} months
                </div>
            );
        }
        return <></>;
    };

    return (
        <div className="scct--fbs-available-terms-container">
            {fbsDecision?.terms && fbsDecision?.terms.length > 1 ? (
                <div className="fbs-available-terms-header">
                    Available Term Lengths
                </div>
            ) : null}
            {getTermTabs()}
            {getSelectedTermRate()}
            {getSelectedTermAPR()}
        </div>
    );
};

export default memo(FbsAvailableTerms);
