import './ImageGalleryImage.scss';

import { memo } from 'react';

const ImageGalleryImage = ({
    height = 300,
    href,
    src,
    width = 400,
    lazyLoad,
    ...rest
}) => {
    const image = (
        <img
            loading={lazyLoad ? 'lazy' : undefined}
            src={src}
            {...rest}
            width={width}
            height={height}
            className="scct--image-gallery__image"
        />
    );

    return href ? (
        <a
            rel="nofollow"
            href={href}
            tabIndex="-1"
            className="scct--image-gallery__image-link"
        >
            {image}
        </a>
    ) : (
        image
    );
};

export default memo(ImageGalleryImage);
