import './ActionMenuPopover.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, memo } from 'react';

import MenuType from './menu.proptypes';

const ActionMenuPopover = memo(
    forwardRef(
        (
            {
                activeIndex,
                criticalMenuItem,
                getFloatingProps,
                getItemProps,
                handleClosePopover,
                listRef,
                menuItems,
                style,
            },
            forwardedRef,
        ) => {
            const handleClick = (itemClickHandler) => {
                handleClosePopover();

                // Timeout helps restore the focus to the button, which can then be reassigned to a modal (or something
                // similar) as determined by the click handler.
                setTimeout(() => {
                    itemClickHandler();
                });
            };

            return (
                <div
                    ref={forwardedRef}
                    className="scct--car-tile-action-menu-content scct--car-tile-action-menu-list"
                    style={style}
                    {...getFloatingProps()}
                >
                    {menuItems.map((item, index) => (
                        <button
                            role="menuitem"
                            type="button"
                            className={classNames(
                                'scct--car-tile-action-menu-list-item',
                                item.className,
                            )}
                            key={item.text}
                            ref={(node) => {
                                listRef.current[index] = node;
                            }}
                            tabIndex={activeIndex === index ? 0 : -1}
                            {...getItemProps({
                                onClick: () => {
                                    handleClick(item.onClick);
                                },
                            })}
                        >
                            {item.icon}
                            {item.text}
                        </button>
                    ))}
                    {criticalMenuItem ? (
                        <>
                            <hr />
                            <button
                                role="menuitem"
                                type="button"
                                className={classNames(
                                    'scct--car-tile-action-menu-list-item critical-item',
                                    criticalMenuItem.className,
                                )}
                                ref={(node) => {
                                    listRef.current[menuItems.length] = node;
                                }}
                                tabIndex={
                                    activeIndex === menuItems.length ? 0 : -1
                                }
                                {...getItemProps({
                                    onClick: () => {
                                        handleClick(criticalMenuItem.onClick);
                                    },
                                })}
                            >
                                {criticalMenuItem.icon}
                                {criticalMenuItem.text}
                            </button>
                        </>
                    ) : null}
                </div>
            );
        },
    ),
);

ActionMenuPopover.displayName = 'ActionMenuPopover';

ActionMenuPopover.propTypes = {
    activeIndex: PropTypes.number,
    criticalMenuItem: MenuType,
    getFloatingProps: PropTypes.func.isRequired,
    getItemProps: PropTypes.func.isRequired,
    handleClosePopover: PropTypes.func.isRequired,
    listRef: PropTypes.object.isRequired,
    menuItems: PropTypes.arrayOf(MenuType).isRequired,
    style: PropTypes.object,
};

export default ActionMenuPopover;
