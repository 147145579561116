// The api for fetching images has changed.
// For now supporting both the old and new shapes.
const legacyImageTypes = [
    'Front Driver Compartment',
    'Dashboard',
    'Trunk/Cargo (Seats Up)',
    'Rear',
];

// Media delivery service (new) exif tags.
const imageExifTags = ['-FDCOMP', '-DASH', '-CARGO-UP', '-E-R'];

export const getImageUrls = (images) => {
    if (!images || !images.length) {
        return [];
    }

    const imageLinks = images
        .filter(
            (image) =>
                image.metadata.exifTag &&
                imageExifTags.some((t) => image.metadata.exifTag.endsWith(t)),
        )
        .sort(
            (a, b) =>
                imageExifTags.findIndex((t) => a.metadata.exifTag.endsWith(t)) -
                imageExifTags.findIndex((t) => b.metadata.exifTag.endsWith(t)),
        )
        .map((image) => ({
            src: image.thumbnailUrl,
            tag: image.metadata.exifTag,
        }));

    return imageLinks;
};

// TODO: All consumers should point to the media delivery service.
export const getLegacyImageUrls = (images) => {
    if (!images || !images.length) {
        return [];
    }

    const imageLinks = images
        .filter((image) => legacyImageTypes.includes(image.metadata.tag))
        .sort(
            (a, b) =>
                legacyImageTypes.indexOf(a.metadata.tag) -
                legacyImageTypes.indexOf(b.metadata.tag),
        )
        .map((image) => ({
            src: `https://img2.carmax.com/${image.path}`
                .replace('{w}', 800)
                .replace('{r}', '4/3'),
            tag: image.metadata.tag,
        }));

    return imageLinks;
};
