import './ActionMenuTrigger.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, memo } from 'react';

import VerticalDotsIcon from '../CarTile/svg/VerticalDotsIcon';

const ActionMenuTrigger = memo(
    forwardRef(
        (
            {
                className,
                getReferenceProps,
                handleSetIsPopoverOpen,
                handleToggleCompare,
                isCarSelectedForCompare = false,
                isCompareMode = false,
                isPopoverOpen,
            },
            forwardedRef,
        ) => {
            const performAction = () => {
                if (isCompareMode) {
                    handleToggleCompare?.();
                } else {
                    handleSetIsPopoverOpen(!isPopoverOpen);
                }
            };

            let buttonAriaLabel = 'More Actions';
            if (isCompareMode) {
                buttonAriaLabel = isCarSelectedForCompare
                    ? 'Remove from Compare'
                    : 'Add to Compare';
            }

            return (
                <button
                    ref={forwardedRef}
                    type="button"
                    className={classNames(
                        'scct--car-tile-action',
                        'action-menu',
                        className,
                    )}
                    tabIndex={0}
                    aria-label={buttonAriaLabel}
                    {...getReferenceProps({
                        onClick: () => {
                            performAction();
                        },
                    })}
                >
                    {!isCompareMode ? (
                        <VerticalDotsIcon />
                    ) : (
                        <>
                            <div
                                className="scct--compare-car__ring"
                                data-testid="check-icon"
                            />
                            <scct-hzn-icon
                                name="check"
                                style={{
                                    color: isCarSelectedForCompare
                                        ? '#fff'
                                        : undefined,
                                }}
                            />
                        </>
                    )}
                </button>
            );
        },
    ),
);

ActionMenuTrigger.displayName = 'ActionMenuTrigger';

ActionMenuTrigger.propTypes = {
    className: PropTypes.string,
    getReferenceProps: PropTypes.func.isRequired,
    handleSetIsPopoverOpen: PropTypes.func.isRequired,
    handleToggleCompare: PropTypes.func,
    isCarSelectedForCompare: PropTypes.bool,
    isCompareMode: PropTypes.bool,
    isPopoverOpen: PropTypes.bool.isRequired,
};

export default ActionMenuTrigger;
