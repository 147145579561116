import './MakeModelInfo.scss';

import PropTypes from 'prop-types';
import { memo } from 'react';

const MakeModelInfo = ({ href, make = '', model = '', year, trim = '' }) => {
    return (
        <div className="scct--make-model-container">
            <h3
                className="scct--make-model-info make-model-info"
                data-testid="make-model-info"
            >
                <a
                    className="scct--make-model-info-link make-model-link"
                    href={href}
                    rel="nofollow"
                >
                    <span className="scct--make-model-info--year-make">
                        {year || ''} {`${make} `}
                    </span>
                    <span className="scct--make-model-info--model-trim">
                        {model} {trim}
                    </span>
                </a>
            </h3>
        </div>
    );
};

MakeModelInfo.propTypes = {
    href: PropTypes.string.isRequired,
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.number,
    trim: PropTypes.string,
};

export default memo(MakeModelInfo);
