import PropTypes from 'prop-types';

import { TestConfigContext } from '@/context/TestConfigContext';

const TestConfigHandler = ({ children, value }) => {
    return (
        <TestConfigContext.Provider value={value}>
            {children}
        </TestConfigContext.Provider>
    );
};

TestConfigHandler.propTypes = {
    children: PropTypes.node,
    value: PropTypes.object,
};

export default TestConfigHandler;
