//Use this to populate prop65 on click tagging
export const getTimestamp = (minuteResolution = 0) => {
    const now = new Date();
    const hours = now.getHours();
    const minutes =
        minuteResolution === 0
            ? now.getMinutes()
            : Math.floor(now.getMinutes() / minuteResolution) *
              minuteResolution;

    return `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${hours < 12 ? 'AM' : 'PM'}`;
};
