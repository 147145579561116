import { Fees, FeeSchedule } from './types/calculator';

export const calculateMonthlyPayment = (
  vehiclePrice: number,
  downPayment: number,
  fee: number,
  term: number,
  rate: number
): number => {
  const total = vehiclePrice - downPayment + fee;

  const formattedRate = rate / 1200;

  // if interest rate is zero
  if (formattedRate === 0) {
    return (vehiclePrice - downPayment + fee) / term;
  }

  const mp = total / ((1 - 1 / Math.pow(1 + formattedRate, term)) / formattedRate);
  return mp;
};

export const calculateVehiclePrice = (
  monthlyPayment: number,
  downPayment: number,
  fee: number,
  term: number,
  rate: number
): number => {
  const formattedRate = rate / 1200;

  // if interest rate is zero
  if (formattedRate === 0) {
    const vp = monthlyPayment * term + downPayment - fee;
    // return vehicle prices
    return vp;
  }

  const total = monthlyPayment * ((1 - 1 / Math.pow(1 + formattedRate, term)) / formattedRate);
  const vp = total + downPayment - fee;
  // return vehicle price
  return vp;
};

export const calculateTotalFinancedAmount = (vehiclePrice: number, downPayment: number, fee: number): number => {
  return vehiclePrice + fee - downPayment;
};

export const lookupAllFeesByVehiclePrice = (feeSchedule: FeeSchedule[], amount: number): Fees => {
  let totalFees = 0;
  let plateTransfer: string[] = [];
  let salesTax: string[] = [];
  let registration = '';
  let title = '';
  let additionalFees = '';

  if (feeSchedule) {
    let selectedFee = null;

    for (const fee of feeSchedule) {
      if (fee.minimum <= amount && fee.maximum >= amount) {
        selectedFee = fee;
        break;
      } else if (fee.minimum <= amount) {
        selectedFee = fee;
      }
    }
    if (selectedFee) {
      if (selectedFee.estimatedFeesMaximum !== 0) {
        totalFees = selectedFee.estimatedFeesMaximum;
      } else {
        totalFees = selectedFee.estimatedFeesMinimum;
      }
      plateTransfer = selectedFee.details.plateTransfer;
      salesTax = selectedFee.details.salesTax;
      registration = selectedFee.details.registration[0];
      title = selectedFee.details.title[0];
      additionalFees = selectedFee.details.additionalFees[0];
    }
  }
  return { totalFees, plateTransfer, salesTax, registration, title, additionalFees };
};

export const lookupFeesByVehiclePrice = (feeSchedule: FeeSchedule[], amount = 0): number | null => {
  const allFees = lookupAllFeesByVehiclePrice(feeSchedule, amount);
  return allFees.totalFees;
};

export const calculateVehicleBudget = (
  monthlyPayment: number,
  downPayment: number,
  taxTitleFees: number,
  termLengthInMonths: number,
  interestRate: number,
  feeSchedule: FeeSchedule[]
): { totalAmount: number; vehiclePrice: number; taxTitleFees: number } => {
  let vehiclePrice = calculateVehiclePrice(monthlyPayment, downPayment, taxTitleFees, termLengthInMonths, interestRate);
  let totalAmount = calculateTotalFinancedAmount(vehiclePrice, downPayment, taxTitleFees);
  let fee = lookupAllFeesByVehiclePrice(feeSchedule, totalAmount);

  let feeRecalc = lookupAllFeesByVehiclePrice(feeSchedule, vehiclePrice);

  let recalcs = 4;

  while (feeRecalc.totalFees !== fee.totalFees) {
    // always skew to higher fees
    if (recalcs <= 0 && fee.totalFees > feeRecalc.totalFees) {
      break;
    }
    fee = feeRecalc;
    vehiclePrice = totalAmount - fee.totalFees + downPayment;

    feeRecalc = lookupAllFeesByVehiclePrice(feeSchedule, vehiclePrice);
    recalcs--;
  }

  vehiclePrice = calculateVehiclePrice(monthlyPayment, downPayment, fee.totalFees, termLengthInMonths, interestRate);
  totalAmount = calculateTotalFinancedAmount(vehiclePrice, downPayment, fee.totalFees);

  return { totalAmount, vehiclePrice, taxTitleFees: fee.totalFees };
};
