import './BaseModal.scss';

import classNames from 'classnames';
import {
    forwardRef,
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ReactModal from 'react-modal';

import { useDidMountEffect } from '@/hooks';

import CloseButton from '../CloseButton/CloseButton';
import CustomScrollbar from '../CustomScrollbar/CustomScrollbar';

const BaseModal = (
    {
        open,
        onClose,
        register,
        title,
        ariaLabel,
        ariaLabelledby,
        ariaDescribedby,
        modalHeader,
        modalFooter,
        className,
        hideScrollbarPlaceholder = true,
        loading = false,
        children: content,
        shouldCloseOnOverlayClick = true,
        showClose = true,
        icon,
    },
    ref,
) => {
    const [modalElement, setModalElement] = useState();
    const [loader, setLoader] = useState(loading);
    const loadingCover = useRef();

    const aria = useMemo(() => {
        const ariaObj = {};
        const label = ariaLabel || title;
        if (label) {
            ariaObj.label = label;
        } else if (ariaLabelledby) {
            ariaObj.labelledby = ariaLabelledby;
        }
        if (ariaDescribedby) ariaObj.describedby = ariaDescribedby;
        return ariaObj;
    }, [title, ariaLabel, ariaLabelledby, ariaDescribedby]);

    const setRef = useCallback(
        (ref) => {
            register(ref);
            ref && setModalElement(ref);
        },
        [register],
    );

    const setCurrentHeight = useCallback(() => {
        if (modalElement) {
            modalElement.removeAttribute('style');
            modalElement.style.height = `${modalElement.getBoundingClientRect().height}px`;
        }
    }, [modalElement, content]);

    useLayoutEffect(() => {
        setCurrentHeight();
        window.addEventListener('resize', setCurrentHeight);
        return () => window.removeEventListener('resize', setCurrentHeight);
    }, [setCurrentHeight]);

    useDidMountEffect(() => {
        setLoader(!!loading);
    }, [loading]);

    return (
        <ReactModal
            appElement={document.getElementById('modal-placeholder')}
            isOpen={open}
            onRequestClose={onClose}
            className={classNames('scct--base-modal', className)}
            overlayClassName="scct--base-modal--overlay"
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            contentRef={setRef}
            aria={aria}
        >
            {showClose && (
                <CloseButton
                    className="scct--base-modal--close"
                    onClick={onClose}
                />
            )}
            <div
                className={classNames('scct--base-modal--top', {
                    'scct--base-modal--empty-title': !title,
                })}
            >
                {icon && <div className="scct--base-modal--icon">{icon}</div>}
                {title && (
                    <div className="scct--base-modal--title">{title}</div>
                )}
            </div>
            {modalHeader && (
                <div className="scct--base-modal--header">{modalHeader}</div>
            )}
            <CustomScrollbar
                className="scct--base-modal--body"
                resizeTarget={modalElement}
                usePadding={false}
                hideScrollbarPlaceholder={hideScrollbarPlaceholder}
                ref={ref}
            >
                <div className="scct--base-modal--scroll">{content}</div>
                {loader && (
                    <div
                        className="scct--base-modal--loading-cover"
                        ref={loadingCover}
                    >
                        <scct-hzn-loading size="small" />
                    </div>
                )}
            </CustomScrollbar>
            {modalFooter && (
                <div className="scct--base-modal--footer">{modalFooter}</div>
            )}
        </ReactModal>
    );
};

export default forwardRef(BaseModal);
