import './FbsTerms.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';

import { formatCurrency } from '@/utilities';

import FbsApplicationType from '../CarTile/fbsApplication.proptypes';
import FbsDecisionType from '../CarTile/fbsDecision.proptypes';
import fbsDecisionLabelFunctions from '../CarTile/fbsDecisionLabelFunctions';
import Label from '../Label/Label';

const FbsTerms = ({
    fbsApplication,
    fbsDecision: fbsDecisionParam,
    vehicleData,
    downPayment,
    padForPendingDecision = false,
    onFbsTermsModalShown,
}) => {
    let padTile = false;
    let fbsDecision = fbsDecisionParam;
    if (!fbsDecision && padForPendingDecision) {
        // Use a dummy decision for padding purposes.
        fbsDecision = {
            terms: [],
            decision: 'declined',
        };
        padTile = true;
    }

    const labelContent = useMemo(() => {
        if (vehicleData.isDisabled) {
            const configFn = fbsDecisionLabelFunctions.default;
            return configFn({ fbsApplication, fbsDecision, downPayment });
        } else {
            const configFn =
                fbsDecisionLabelFunctions[
                    fbsDecision?.decision?.toLowerCase()
                ] || fbsDecisionLabelFunctions.default;
            return configFn({ fbsApplication, fbsDecision, downPayment });
        }
    }, [fbsDecision, padForPendingDecision]);

    const getTermLength = () => {
        return fbsDecision?.terms[0]?.length;
    };

    const getMinApr = () => {
        return fbsDecision?.terms[0]?.minTermRate.apr || 0;
    };

    const getMaxApr = () => {
        return fbsDecision?.terms[0]?.maxTermRate.apr || 0;
    };

    const getMinPayment = () => {
        if (fbsDecision?.terms[0]) {
            return formatCurrency(
                fbsDecision?.terms[0]?.minTermRate?.monthlyPayment || 0,
            );
        }
        return 0;
    };

    const getMaxPayment = () => {
        if (fbsDecision?.terms[0]) {
            return formatCurrency(
                fbsDecision?.terms[0]?.maxTermRate?.monthlyPayment || 0,
            );
        }
        return 0;
    };

    return (
        <div className={classNames('scct--fbs-terms', { padded: padTile })}>
            {labelContent.hasTerms ? (
                <>
                    {getMinPayment() === getMaxPayment()
                        ? `${getMinPayment()}/mo`
                        : `${getMinPayment()}-${getMaxPayment()}/mo`}
                    <div className="scct--fbs-terms--rate-term">
                        {getMinApr() === getMaxApr()
                            ? `${getMinApr()}% APR`
                            : `${getMinApr()}%-${getMaxApr()}% APR`}
                        <span className="scct--fbs-terms--bullet">&#8226;</span>
                        {getTermLength()} months
                    </div>
                </>
            ) : null}
            <Label
                content={labelContent}
                className={!labelContent?.hasTerms ? 'no-terms' : ''}
                fbsApplication={fbsApplication}
                fbsDecision={fbsDecision}
                vehicleData={vehicleData}
                isDisabled={vehicleData.isDisabled}
                downPayment={downPayment}
                onFbsTermsModalShown={onFbsTermsModalShown}
            />
        </div>
    );
};

FbsTerms.propTypes = {
    fbsApplication: FbsApplicationType.isRequired,
    fbsDecision: FbsDecisionType,
    onFbsTermsModalShown: PropTypes.func,
    vehicleData: PropTypes.shape({
        basePrice: PropTypes.number,
        feesList: PropTypes.arrayOf(
            PropTypes.shape({
                minimum: PropTypes.number,
                maximum: PropTypes.number,
                estimatedFeesMinimum: PropTypes.number,
                estimatedFeesMaximum: PropTypes.number,
                details: PropTypes.shape({
                    salesTax: PropTypes.arrayOf(PropTypes.string),
                    title: PropTypes.arrayOf(PropTypes.string),
                    registration: PropTypes.arrayOf(PropTypes.string),
                    plateTransfer: PropTypes.arrayOf(PropTypes.string),
                    additionalFees: PropTypes.arrayOf(PropTypes.string),
                    total: PropTypes.string,
                    highFeeEstimate: PropTypes.number,
                }),
            }),
        ),
    }).isRequired,
    downPayment: PropTypes.number,
    padForPendingDecision: PropTypes.bool,
};

export default memo(FbsTerms);
