import PropTypes from 'prop-types';

const MenuType = PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.any,
    className: PropTypes.string,
});

export default MenuType;
